import React, { useState } from 'react';
import {FaArrowLeft} from 'react-icons/fa'

const PostReliv = () => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [world, setWorld] = useState('');

  return (
    <div className="dark text-white min-h-screen flex flex-col items-center justify-center p-4 relative">
      <button onClick={() => window.history.back()} className="absolute top-4 left-4 flex items-center space-x-2 text-white text-lg font-semibold">
        <FaArrowLeft />
      </button>
      <div className="max-w-4xl w-full grid grid-cols-2 gap-8">
        {/* Left Section: Form */}
        <div className="space-y-6">
          <h1 className="text-3xl font-bold">Create A Relive Experience</h1>

          <div className="space-y-4">
            <h2 className="text-lg font-semibold uppercase">Relive Post</h2>

            {/* Title Input */}
            <label className="block uppercase text-sm font-semibold mb-2">
              Write a Catchy Title
            </label>
            <input
              type="text"
              placeholder="Day Well Spent"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="bg-transparent border border-orange-500 p-2 rounded w-full"
            />

            {/* Description Input */}
            <label className="block uppercase text-sm font-semibold mb-2">
              Write a Description
            </label>
            <textarea
              placeholder="Describe your day..."
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="bg-transparent border border-orange-500 p-2 rounded w-full h-24"
            ></textarea>

            {/* Select Video Button */}
            <label className="block uppercase text-sm font-semibold mb-2">
              Choose a Video
            </label>
            <button className="bg-orange-500 text-black font-bold py-2 px-3 rounded">
              Select Video
            </button>

            {/* World Dropdown */}
            <label className="block uppercase text-sm font-semibold mb-2">
              Choose World
            </label>
            <select
              value={world}
              onChange={(e) => setWorld(e.target.value)}
              className="bg-transparent border border-orange-500 p-2 rounded w-full"
            >
              <option value="">Select World</option>
              <option value="world1">World 1</option>
              <option value="world2">World 2</option>
              <option value="world3">World 3</option>
            </select>

            {/* Post Button */}
            <button className="bg-orange-500 text-black font-bold py-2 px-4 rounded mt-2">
              POST
            </button>
          </div>
        </div>

        {/* Right Section: Preview Image */}
        <div className="relative">
          <img
            src="https://via.placeholder.com/300x450" // Replace with actual image
            alt="Preview"
            className="rounded-lg w-full h-full object-cover"
          />

       
          {/* Bottom Overlay with Text */}
          <div className="absolute bottom-0 left-0 w-full bg-black bg-opacity-75 p-2">
            <p className="font-semibold text-sm">JESSIE THOMPSON</p>
            <h3 className="text-lg font-bold">Day Well Spent</h3>
            <p className="text-sm">
              Yesterday was such a blast! My two friends and I decided to make
              the most of the day, starting with lunch at Alto by San Carlo.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostReliv;
