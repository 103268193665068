import React from 'react';
import { FaArrowLeft } from 'react-icons/fa';

const Profile = () => {
  const friendsImages = [
    "https://images.unsplash.com/photo-1494790108377-be9c29b29330?w=150&h=150&fit=crop&crop=faces",
    "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?w=150&h=150&fit=crop&crop=faces",
    "https://images.unsplash.com/photo-1580489944761-15a19d654956?w=150&h=150&fit=crop&crop=faces",
    "https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?w=150&h=150&fit=crop&crop=faces",
    "https://images.unsplash.com/photo-1517841905240-472988babdf9?w=150&h=150&fit=crop&crop=faces",
  ];
  const experienceImages = [
    "https://images.unsplash.com/photo-1522202176988-66273c2fd55f?w=300&h=300&fit=crop",
    "https://images.unsplash.com/photo-1504674900247-0877df9cc836?w=300&h=300&fit=crop",
    "https://images.unsplash.com/photo-1540206395-68808572332f?w=300&h=300&fit=crop",
    "https://images.unsplash.com/photo-1528892952291-009c663ce843?w=300&h=300&fit=crop",
    "https://images.unsplash.com/photo-1517849845537-4d257902454a?w=300&h=300&fit=crop",
  ];
  const weeks = [
    { weekNumber: 41, dates: ['7', '8', '9', '10', '11', '12', '13'] },
    { weekNumber: 40, dates: ['30', '1', '2', '3', '4', '5', '6'] },
    { weekNumber: 39, dates: ['23', '24', '25', '26', '27', '28', '29'] },
  ];

  return (
    <div className="min-h-screen text-white p-6">
      
      <div className="max-w-5xl mx-auto">
        {/* Header */}
        <div className="flex justify-between items-center mb-8">
          <div className="flex items-center">
            <FaArrowLeft className="text-white text-2xl mr-4 cursor-pointer" onClick={() => window.history.back()} />
            <h1 className="text-4xl font-bold tracking-wider">Profile</h1>
          </div>
          <button className="bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 px-4 rounded">
            YOUR WORLDS
          </button>
        </div>

        {/* Info and Profile Picture */}
        <div className="flex flex-col md:flex-row justify-between">
          <div className="mb-8 md:mb-0">
            <h2 className="text-2xl font-bold mb-4">INFO</h2>
            <div className="space-y-2">
              <InfoItem label="NAME" value="JESSICA THOMPSON" />
              <InfoItem label="EMAIL" value="JESSICA@EXAMPLE.COM" />
              <InfoItem label="PHONE NUMBER" value="0712345678" />
              <InfoItem label="UNIVERSITY" value="MIDDLESEX UNIVERSITY" />
            </div>
          </div>
          <div className="w-48 h-48">
            <img
              src="https://via.placeholder.com/200x200"
              alt="Profile"
              className="w-full h-full rounded-full object-cover"
            />
          </div>
        </div>

        {/* Friends Section */}
        <div className="mt-12">
          <h2 className="text-2xl font-bold mb-4">FRIENDS</h2>
          <div className="flex space-x-4">
            {friendsImages.map((src, i) => (
              <img
                key={i}
                src={src}
                alt={`Friend ${i + 1}`}
                className="w-28 h-28 rounded-full object-cover"
              />
            ))}
            <div className="w-28 h-28 rounded-full bg-gray-700 flex items-center justify-center text-sm">
              +154
            </div>
          </div>
        </div>
        <div className="mt-12">
          <h2 className="text-2xl font-bold mb-4">RELIVE EXPERIENCES</h2>
          <div className="grid grid-cols-2 md:grid-cols-5 gap-4">
            {experienceImages.map((src, i) => (
              <div key={i} className="aspect-square">
                <img
                  src={src}
                  alt={`Experience ${i + 1}`}
                  className="w-full h-full object-cover"
                />
              </div>
            ))}
          </div>
        </div>
        {/* Relive Experiences Section */}
        <div className="mt-12">
          <h2 className="text-2xl font-bold mb-4">RELIVE EXPERIENCES</h2>
          <div className="space-y-4">
            {weeks.map((week) => (
              <div key={week.weekNumber} className="bg-gray-900 rounded-lg p-4">
                <div className="flex justify-between items-center mb-2">
                  <span className="text-xl text-gray-400">Week {week.weekNumber}</span>
                  <button className="text-gray-400 hover:text-white">...</button>
                </div>
                <div className="grid grid-cols-7 gap-2">
                  {week.dates.map((date, index) => (
                    <div 
                      key={index} 
                      className={`aspect-square bg-gray-800 rounded-lg flex items-center justify-center ${
                        index === 0 && week.weekNumber === 41 ? 'bg-opacity-50' : ''
                      }`}
                    >
                      {index === 0 && week.weekNumber === 41 ? (
                        <img 
                          src="https://images.unsplash.com/photo-1522202176988-66273c2fd55f?w=100&h=100&fit=crop" 
                          alt="Experience" 
                          className="w-full h-full object-cover rounded-lg"
                        />
                      ) : index === 1 && week.weekNumber === 41 ? (
                        <span className="text-3xl text-gray-600">+</span>
                      ) : (
                        <span className="text-xl font-bold text-gray-600">{date}</span>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const InfoItem = ({ label, value }) => (
  <div className="flex">
    <span className="font-bold w-40">{label}</span>
    <span className="text-gray-400">{value}</span>
  </div>
);

export default Profile;