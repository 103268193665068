import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { 
  FaBars, 
  FaChevronLeft, 
  FaChevronRight, 
  FaCalendarAlt, 
  FaBriefcase, 
  FaGlassMartini, 
  FaYelp, 
  FaHome, 
  FaGraduationCap, 
  FaUtensils, 
  FaSignOutAlt,
  FaCog
} from 'react-icons/fa';
import { useAuth } from "../../../auth/userProvider/AuthProvider.js";
import { getDoc, doc } from "firebase/firestore";
import { firestore } from "../../../firebase.js";
import animationData from "../../../assets/lotties/loader1.json";
import Logo from '../../../assets/img/log.png';
import globe1 from '../../../assets/img/globe2.png';
import Lottie from "react-lottie";
import { Toaster } from 'react-hot-toast';
import DashboardNav from "./dashboardNav.js";

const UserDashboard = () => {
  const auth = useAuth();
  const { logout } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [initialLoad, setInitialLoad] = useState(true);
  const [userData, setUserData] = useState(null);
  const [userLocation, setUserLocation] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(true);
  const [selectedOption, setSelectedOption] = useState(null); // For tracking selected option

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (!auth || !auth.currentUser) return;

    const fetchData = async () => {
      try {
        const userDocRef = doc(firestore, "users", auth.currentUser.uid);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          const data = userDoc.data();
          setUserData(data);
          localStorage.setItem('userData', JSON.stringify(data));
        } else {
          console.log("User document does not exist");
        }
      } catch (error) {
        console.error("Error fetching user data:", error.message);
      }

      setLoading(false);
      setInitialLoad(false);
    };

    fetchData();
  }, [auth]);

  useEffect(() => {
    const getUserLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const location = {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            };
            setUserLocation(location);
            localStorage.setItem("userLocation", JSON.stringify(location));
          },
          (error) => {
            console.error("Error fetching location:", error.message);
          },
          { timeout: 10000, enableHighAccuracy: true }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
      }
    };

    if (auth.currentUser) {
      getUserLocation();
    }
  }, [auth]);

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
      console.log("User logged out successfully");
    } catch (error) {
      console.error("Logout error:", error.message);
    }
  };

  const toggleSidebar = () => {
    setIsSidebarExpanded(!isSidebarExpanded);
  };

  const navigationItems = [
    { label: 'University', icon: <FaGraduationCap className="w-8 h-6" />, path: '/user-dashboard/university' },
    { label: 'Accomodation', icon: <FaHome className="w-8 h-6" />, path: '/user-dashboard/stay' },
    { label: 'Events', icon: <FaGlassMartini className="w-8 h-6" />, path: '/user-dashboard/events' },
    { label: 'Jobs', icon: <FaBriefcase className="w-8 h-6" />, path: '/user-dashboard/jobs' },
    { label: 'Explore', icon: <FaUtensils className="w-8 h-6" />, path: '/user-dashboard/explore' },
    { label: 'Reliv', icon: <FaYelp className="w-8 h-6" />, path: '/user-dashboard/reliv' },
    { label: 'Bookings', icon: <FaCalendarAlt className="w-8 h-6" />, path: '/user-dashboard/bookings' },
  ];

  const handleKeyDown = (event, index) => {
    if (event.key === 'Enter') {
      navigate(navigationItems[index].path);
      setSelectedOption(index);
    } else if (event.key === 'ArrowDown') {
      setSelectedOption((prev) => (prev + 1) % navigationItems.length);
    } else if (event.key === 'ArrowUp') {
      setSelectedOption((prev) => (prev - 1 + navigationItems.length) % navigationItems.length);
    }
  };

  const handleNavigationClick = (index, path) => {
    navigate(path);
    setSelectedOption(index);
  };

  if (initialLoad) {
    return (
      <div className="overlay-container">
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: animationData,
          }}
          height={200}
          width={200}
        />
      </div>
    );
  }

  return (
    <div className="user-dashboard bg-gray-900">
      {isMobile && (
        <div style={{ position: 'sticky', top: 0, zIndex: 1000 }}>
          <DashboardNav userLocation={userLocation} />
        </div> 
      )}
      {/*sidebar */}
      {!loading && (
        <div className="content">
          {!isMobile && (
            <div
            className={`sidebar bg-black text-white z-10 transition-all duration-300 ${
              isSidebarExpanded ? 'w-60' : 'w-20'
            }`}
            style={{
              position: 'fixed',
              height: '100vh',
              top: 0,
              left: 0,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {/* Logo section */}
            <div className="flex justify-center items-center p-4">
              {isSidebarExpanded ? (
                <div className="w-full cursor-pointer" onClick={() => navigate('/user-dashboard/reliv/myworlds')}>
                  <img src={Logo} alt="WorldLynk Icon" className="w-full h-auto" />
                </div>
              ) : (
                <div className="w-full flex justify-center cursor-pointer" onClick={() => navigate('/user-dashboard/reliv/myworlds')}>
                  <img 
                    src={globe1} 
                    alt="WorldLynk Small Icon" 
                    className="w-8 h-8" // Adjust size as needed
                  />
                </div>
              )}
            </div>

              {/* Navigation section */}
              <div className="flex-grow overflow-y-auto space-y-2">
  {navigationItems.map(({ label, icon, path }, index) => (
    <div
      key={index}
      className={`nav-item cursor-pointer ${
        selectedOption === index ? 'bg-gray-700' : ''
      }`}
      tabIndex={0}
      onClick={() => handleNavigationClick(index, path)}
      onKeyDown={(event) => handleKeyDown(event, index)}
      style={{ outline: 'none' }} // Add custom focus handling for accessibility
    >
      <div
        className={`flex items-center ${
          isSidebarExpanded ? 'py-3 px-4' : 'py-3 justify-center'
        } hover:${
          isSidebarExpanded ? 'bg-gray-700' : 'bg-gray-700 rounded-full'
        } rounded-md transition-all duration-300`}
      >
        <div className="ml-1">{icon}</div>
        <span className={`nav-text ml-4 ${!isSidebarExpanded && 'hidden'}`}>
          {label}
        </span>
      </div>
    </div>
  ))}
</div>



              {/* Bottom section for logout and toggle */}
              <div className="mt-auto pb-6">
                {/* Logout Button */}
                <div 
                  className={`logout cursor-pointer w-full ${!isSidebarExpanded ? 'flex justify-center' : ''}`}
                  onClick={handleLogout}
                >
                  <div className="flex items-center py-3 px-2 mx-2 rounded-md hover:bg-gray-700">
                    <FaSignOutAlt className="w-8 h-6 ml-1 text-white" />
                    <span className={`nav-text ml-4 ${!isSidebarExpanded && 'hidden'}`}>
                      Logout
                    </span>
                  </div>
                </div>

             {/* Toggle Button Positioned Outside of Sidebar */}
<div 
  className={`toggle-button absolute cursor-pointer transition-all duration-300`}
  style={{
    top: '50%',
    left: isSidebarExpanded ? '240px' : '80px', // Adjust for sidebar width
    transform: 'translateY(-50%)',
    zIndex: 20, // Ensure it stays above sidebar
  }}
  onClick={toggleSidebar}
>
  <div 
    className="flex justify-center items-center bg-gray-800 rounded-full w-8 h-8"
  >
    {isSidebarExpanded ? (
      <FaChevronLeft className="text-white" />
    ) : (
      <FaChevronRight className="text-white" />
    )}
  </div>
</div>

                {/* Settings Button */}
                <div 
                  className={`logout cursor-pointer w-full ${!isSidebarExpanded ? 'flex justify-center' : ''}`}
                  onClick={handleLogout}
                >
                  <div className="flex items-center py-3 px-2 mx-2 rounded-md hover:bg-gray-700">
                    <FaCog className="w-8 h-6 ml-1 text-white" />
                    <span className={`nav-text ml-4 ${!isSidebarExpanded && 'hidden'}`}>
                      Settings
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Main Content */}
          <div
            className="flex-grow"
            style={{
              marginLeft: !isMobile ? (isSidebarExpanded ? '15%' : '5%') : '0',
              padding: '20px',
            }}
          >
            <Toaster />
            {userData ? (
              <Outlet context={{ user: userData, location: userLocation }} />
            ) : (
              <div>Loading...</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default UserDashboard;
