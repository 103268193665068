import React, { useState } from 'react';
import { FaArrowLeft, FaTrash } from 'react-icons/fa'; // Import the minus icon
import { useNavigate } from 'react-router-dom';
const AddExperience = () => {
  const [selectedType, setSelectedType] = useState('day out');
  const navigate = useNavigate();
  const [experiences, setExperiences] = useState([{ 
    time: '', 
    type: 'restaurant', 
    name: '', 
    link: '', 
    picture: '', 
    links: [], 
    notes: [] 
  }]);

  const handleAddExperience = () => {
    setExperiences([...experiences, { 
      time: '', 
      type: 'restaurant', 
      name: '', 
      link: '', 
      picture: '', 
      links: [], 
      notes: [] 
    }]);
  };

  const handleRemoveExperience = (index) => {
    const updatedExperiences = experiences.filter((_, i) => i !== index);
    setExperiences(updatedExperiences);
  };

  const handleChange = (index, field, value) => {
    const updatedExperiences = [...experiences];
    updatedExperiences[index][field] = value;
    setExperiences(updatedExperiences);
  };

  const handleAddField = (index, field) => {
    const updatedExperiences = [...experiences];
    updatedExperiences[index][field].push({ name: '', value: '' });
    setExperiences(updatedExperiences);
  };

  return (
    <div className="dark text-white min-h-screen flex flex-col items-center py-8 px-4 relative rounded-lg">
      {/* Back Arrow */}
      <button onClick={() => window.history.back()} className="absolute top-4 left-4 flex items-center space-x-2 text-white text-lg font-semibold">
        <FaArrowLeft />
      </button>

      <h1 className="text-3xl font-bold mb-6">Create A Reliv Experience</h1>

      {/* Type of Experience */}
      <div className="w-full max-w-xl space-y-6 p-4 shadow-lg">
        <h2 className="text-lg font-semibold mb-2">Type of Experience</h2>
        <div className="flex space-x-4">
          {['day out', 'trip', 'custom'].map((type) => (
            <button
              key={type}
              onClick={() => setSelectedType(type)}
              className={`px-4 py-2 rounded ${
                selectedType === type
                  ? 'bg-orange-500 text-black'
                  : 'bg-gray-700'
              }`}
            >
              {type.toUpperCase()}
            </button>
          ))}
        </div>
      </div>

      {experiences.map((exp, index) => (
        <div key={index} className="w-full max-w-xl space-y-6 mb-10 p-4 shadow-lg relative">
          {/* Remove Experience Button */}
          <div className="flex items-center justify-between">
            <h2 className="text-xl font-semibold">Experience {index + 1}</h2>
            <button
              className="text-white hover:text-red-500"
              onClick={() => handleRemoveExperience(index)}
            >
              <FaTrash />
            </button>
          </div>

          {/* Time Selector */}
          <div className="flex items-center space-x-4">
            <label className="w-24 font-medium">Time:</label>
            <input
              type="time"
              value={exp.time}
              onChange={(e) => handleChange(index, 'time', e.target.value)}
              className="bg-gray-800 p-2 rounded w-full"
            />
          </div>

          {/* Type of Place */}
          <div>
            <label className="font-medium mb-2 block">Type of Place:</label>
            <div className="flex space-x-4">
              {['restaurant', 'shopping', 'places', 'activities', 'events'].map((type) => (
                <button
                  key={type}
                  onClick={() => handleChange(index, 'type', type)}
                  className={`px-3 py-2 rounded ${
                    exp.type === type ? 'bg-orange-500 text-black' : 'bg-gray-700'
                  }`}
                >
                  {type.charAt(0).toUpperCase() + type.slice(1)}
                </button>
              ))}
            </div>
          </div>

          {/* Select Restaurant Dropdown */}
          <div className="mb-4">
            <h3 className="text-lg font-semibold mb-2">Select the Restaurant</h3>
            <select
              onChange={(e) => handleChange(index, 'name', e.target.value)}
              className="bg-gray-800 p-2 rounded w-full"
            >
              <option value="">Choose a Restaurant</option>
              <option value="Alto by San Carlos">Alto by San Carlos</option>
              <option value="Blue Lagoon">Blue Lagoon</option>
              <option value="Sunset Grill">Sunset Grill</option>
            </select>
          </div>

          {/* OR Separator */}
          <div className="flex items-center my-4">
            <hr className="w-full border-gray-600" />
            <span className="px-4 text-sm font-medium">or</span>
            <hr className="w-full border-gray-600" />
          </div>

          {/* Add Custom Restaurant Section */}
          <div className="space-y-4">
            <h3 className="text-lg font-semibold mb-2">Add Your Restaurant</h3>

            <div className="flex items-center space-x-4">
              <label className="w-24 font-medium">Name:</label>
              <input
                type="text"
                value={exp.name}
                onChange={(e) => handleChange(index, 'name', e.target.value)}
                className="bg-gray-800 p-2 rounded w-full"
              />
            </div>

            <div className="flex items-center space-x-4">
              <label className="w-24 font-medium">Link:</label>
              <input
                type="text"
                value={exp.link}
                onChange={(e) => handleChange(index, 'link', e.target.value)}
                className="bg-gray-800 p-2 rounded w-full"
              />
            </div>

            <div className="flex items-center space-x-4">
              <label className="w-24 font-medium">Picture:</label>
              <input
                type="file"
                onChange={(e) => {
                  const file = e.target.files[0];
                  const reader = new FileReader();
                  reader.onloadend = () => {
                    handleChange(index, 'picture', reader.result);
                  };
                  reader.readAsDataURL(file);
                }}
                className="bg-gray-800 p-2 rounded w-full"
              />
            </div>
          </div>
        </div>
      ))}

      {/* Add Experience Button */}
      <button
        onClick={handleAddExperience}
        className="bg-orange-500 px-4 py-2 rounded text-black font-bold mb-4"
      >
        + Add Experience
      </button>

      {/* Go to Itinerary Button */}
      <button onClick={() => navigate('/user-dashboard/reliv/create/itinerary')} className="bg-green-500 px-4 py-2 rounded text-black font-bold">
        Go to Itinerary
      </button>
    </div>
  );
};

export default AddExperience;
