import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

const AddPost = () => {
  const [image, setImage] = useState(null);
  const [caption, setCaption] = useState('');
  const [tags, setTags] = useState('');
  const [location, setLocation] = useState('');
  const [imageRatio, setImageRatio] = useState('1:1');
  const navigate = useNavigate();

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleBack = () => {
    navigate(-1);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle post submission logic here
    console.log({ image, caption, tags, location, imageRatio });
  };
  const getImageContainerClass = () => {
    switch (imageRatio) {
      case '3:2':
        return 'w-full aspect-[3/2]'; // 3:2 ratio
      case '5:4':
        return 'w-full aspect-[5/4]'; // 5:4 ratio
      case '16:9':
        return 'w-full aspect-[16/9]'; // 16:9 ratio
      case '9:16':
        return 'w-full aspect-[9/16]'; // 9:16 ratio
      default:
        return 'w-full aspect-square'; // 1:1 ratio (square)
    }
  };
  

  return (
    <div className="bg-gray-900 min-h-screen p-4">
        
      <div className="max-w-2xl mx-auto">
      <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={handleBack}
          className="text-white p-2 rounded-full transition-colors duration-75 ease-in hover:bg-gray-700"
          aria-label="Back"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
          </svg>
        </motion.button>
        <h1 className="text-3xl font-bold text-white mb-6">Create New Post</h1>
        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="space-y-2">
            <label htmlFor="image-upload" className="block text-sm font-medium text-gray-300">
              Upload Image
            </label>
            <div className="flex justify-center space-x-4 mb-4">
              {['1:1', '3:2', '5:4', '16:9', '9:16'].map((ratio) => (
                <motion.button
                  key={ratio}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  type="button"
                  onClick={() => setImageRatio(ratio)}
                  className={`px-4 py-2 rounded-lg text-sm font-medium ${
                    imageRatio === ratio
                      ? 'bg-blue-600 text-white'
                      : 'bg-gray-700 text-gray-300 hover:bg-gray-600'
                  }`}
                >
                  {ratio}
                </motion.button>
              ))}
            </div>
            <div className="flex items-center justify-center w-full">
              <div className="w-3/4 max-w-md">
                <label
                  htmlFor="image-upload"
                  className={`flex flex-col items-center justify-center ${getImageContainerClass()} border-2 border-gray-600 border-dashed rounded-lg cursor-pointer bg-gray-800 hover:bg-gray-700`}
                >
                  {image ? (
                    <img src={image} alt="Uploaded" className="w-full h-full object-cover rounded-lg" />
                  ) : (
                    <div className="flex flex-col items-center justify-center pt-5 pb-6">
                      <svg className="w-10 h-10 mb-3 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path>
                      </svg>
                      <p className="mb-2 text-sm text-gray-400"><span className="font-semibold">Click to upload</span> or drag and drop</p>
                      <p className="text-xs text-gray-400">PNG, JPG or GIF (MAX. 800x400px)</p>
                    </div>
                  )}
                  <input id="image-upload" type="file" className="hidden" onChange={handleImageUpload} accept="image/*" />
                </label>
              </div>
            </div>
          </div>

          <div className="space-y-2">
            <label htmlFor="caption" className="block text-sm font-medium text-gray-300">
              Caption
            </label>
            <textarea
              id="caption"
              rows="3"
              className="w-full px-3 py-2 text-white bg-gray-800 border border-gray-700 rounded-lg focus:outline-none focus:border-blue-500"
              placeholder="Write a caption..."
              value={caption}
              onChange={(e) => setCaption(e.target.value)}
            ></textarea>
          </div>

          <div className="space-y-2">
            <label htmlFor="tags" className="block text-sm font-medium text-gray-300">
              Tags
            </label>
            <input
              type="text"
              id="tags"
              className="w-full px-3 py-2 text-white bg-gray-800 border border-gray-700 rounded-lg focus:outline-none focus:border-blue-500"
              placeholder="Add tags separated by commas"
              value={tags}
              onChange={(e) => setTags(e.target.value)}
            />
          </div>

          <div className="space-y-2">
            <label htmlFor="location" className="block text-sm font-medium text-gray-300">
              Location
            </label>
            <input
              type="text"
              id="location"
              className="w-full px-3 py-2 text-white bg-gray-800 border border-gray-700 rounded-lg focus:outline-none focus:border-blue-500"
              placeholder="Add location"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
            />
          </div>

          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            type="submit"
            className="w-full px-4 py-2 text-white bg-blue-600 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
          >
            Post
          </motion.button>
        </form>
      </div>
    </div>
  );
};

export default AddPost;