const HotelData1 ={
    "Export":[
        {
            "City": "London",
            "Asset": "Aldgate",
            "Name": "Aldg-Floor 10-1003",
            "Room Type": "Gold Studio Premium",
            "Term Session": "2024-25 51wks Starting 07/09/2024",
            "Session Booked Status": "Available",
            "Length (Wks)": 51,
            "Rate": 461,
            "Start Date": "07/09/24",
            "End Date": "30/08/25",
            "Term Active Status": "Active",
            "Closed?": "Open",
            "Current Room Status": "Available",
            "Image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2020-12/iQ-Student-Accommodation-London-Aldgate-Bedrooms-Platinum_Studio%284%29.jpg?itok=R3Q3v5SD"
           },
           {
            "City": "London",
            "Asset": "Aldgate",
            "Name": "Aldg-Floor 10-1012",
            "Room Type": "Gold Studio Premium",
            "Term Session": "2024-25 51wks Starting 07/09/2024",
            "Session Booked Status": "Available",
            "Length (Wks)": 51,
            "Rate": 461,
            "Start Date": "07/09/24",
            "End Date": "30/08/25",
            "Term Active Status": "Active",
            "Closed?": "Open",
            "Current Room Status": "Available",
            "Image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2020-12/iQ-Student-Accommodation-London-Aldgate-Bedrooms-Platinum_Studio%284%29.jpg?itok=R3Q3v5SD"
           },
           {
            "City": "London",
            "Asset": "Aldgate",
            "Name": "Aldg-Floor 10-1013",
            "Room Type": "Gold Studio Premium",
            "Term Session": "2024-25 51wks Starting 07/09/2024",
            "Session Booked Status": "Available",
            "Length (Wks)": 51,
            "Rate": 461,
            "Start Date": "07/09/24",
            "End Date": "30/08/25",
            "Term Active Status": "Active",
            "Closed?": "Open",
            "Current Room Status": "Available",
            "Image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2020-12/iQ-Student-Accommodation-London-Aldgate-Bedrooms-Platinum_Studio%284%29.jpg?itok=R3Q3v5SD"
           },
           {
            "City": "London",
            "Asset": "Aldgate",
            "Name": "Aldg-Floor 10-1014",
            "Room Type": "Gold Studio Premium",
            "Term Session": "2024-25 51wks Starting 07/09/2024",
            "Session Booked Status": "Available",
            "Length (Wks)": 51,
            "Rate": 461,
            "Start Date": "07/09/24",
            "End Date": "30/08/25",
            "Term Active Status": "Active",
            "Closed?": "Open",
            "Current Room Status": "Available",
            "Image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2020-12/iQ-Student-Accommodation-London-Aldgate-Bedrooms-Platinum_Studio_Plus%283%29.jpg?itok=JPp-DhWZ"
           },
           {
            "City": "London",
            "Asset": "Aldgate",
            "Name": "Aldg-Floor 10-1018",
            "Room Type": "Gold Studio Premium",
            "Term Session": "2024-25 51wks Starting 07/09/2024",
            "Session Booked Status": "Available",
            "Length (Wks)": 51,
            "Rate": 461,
            "Start Date": "07/09/24",
            "End Date": "30/08/25",
            "Term Active Status": "Active",
            "Closed?": "Open",
            "Current Room Status": "Available",
            "Image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2020-12/iQ-Student-Accommodation-London-Aldgate-Bedrooms-Platinum_Studio_Plus%283%29.jpg?itok=JPp-DhWZ"
           },
           {
            "City": "London",
            "Asset": "Aldgate",
            "Name": "Aldg-Floor 10-1020",
            "Room Type": "Gold Studio Premium",
            "Term Session": "2024-25 51wks Starting 07/09/2024",
            "Session Booked Status": "Available",
            "Length (Wks)": 51,
            "Rate": 461,
            "Start Date": "07/09/24",
            "End Date": "30/08/25",
            "Term Active Status": "Active",
            "Closed?": "Open",
            "Current Room Status": "Available",
            "Image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2020-12/iQ-Student-Accommodation-London-Aldgate-Bedrooms-Platinum_Studio_Plus%283%29.jpg?itok=JPp-DhWZ"
           },
           {
            "City": "London",
            "Asset": "Aldgate",
            "Name": "Aldg-Floor 10-1021",
            "Room Type": "Gold Studio Premium",
            "Term Session": "2024-25 51wks Starting 07/09/2024",
            "Session Booked Status": "Available",
            "Length (Wks)": 51,
            "Rate": 461,
            "Start Date": "07/09/24",
            "End Date": "30/08/25",
            "Term Active Status": "Active",
            "Closed?": "Open",
            "Current Room Status": "Available",
            "Image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2020-12/iQ-Student-Accommodation-London-Aldgate-Bedrooms-Twin_Room%284%29.jpg?itok=voYIn8S4"
           },
           {
            "City": "London",
            "Asset": "Aldgate",
            "Name": "Aldg-Floor 11-1103",
            "Room Type": "Gold Studio Premium",
            "Term Session": "2024-25 51wks Starting 07/09/2024",
            "Session Booked Status": "Available",
            "Length (Wks)": 51,
            "Rate": 461,
            "Start Date": "07/09/24",
            "End Date": "30/08/25",
            "Term Active Status": "Active",
            "Closed?": "Open",
            "Current Room Status": "Available",
            "Image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2024-10/117-10_1.jpg?itok=z0sTLtLU"
           },
           {
            "City": "Manchester",
            "Asset": "Fletcher House",
            "Name": "Flet-L01-C",
            "Room Type": "Platinum 1 Bed",
            "Term Session": "2024-25 51wks Starting 14/09/2024",
            "Session Booked Status": "Available",
            "Length (Wks)": 51,
            "Rate": 426,
            "Start Date": "14/09/24",
            "End Date": "06/09/25",
            "Term Active Status": "Active",
            "Closed?": "Open",
            "Current Room Status": "Available",
            "Image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2024-08/2%20Bedroom%20Flat-2-min.jpg?itok=L39E8ynN"
           },
           {
            "City": "Manchester",
            "Asset": "Fletcher House",
            "Name": "Flet-L01-D1",
            "Room Type": "Platinum Duplex 2 Bed",
            "Term Session": "2024-25 51wks Starting 14/09/2024",
            "Session Booked Status": "Available",
            "Length (Wks)": 51,
            "Rate": 347,
            "Start Date": "14/09/24",
            "End Date": "06/09/25",
            "Term Active Status": "Active",
            "Closed?": "Open",
            "Current Room Status": "Available",
            "Image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2024-08/2%20Bedroom%20Flat-2-min.jpg?itok=L39E8ynN"
           },
           {
            "City": "Manchester",
            "Asset": "Fletcher House",
            "Name": "Flet-L01-D2",
            "Room Type": "Platinum Duplex 2 Bed",
            "Term Session": "2024-25 51wks Starting 14/09/2024",
            "Session Booked Status": "Available",
            "Length (Wks)": 51,
            "Rate": 347,
            "Start Date": "14/09/24",
            "End Date": "06/09/25",
            "Term Active Status": "Active",
            "Closed?": "Open",
            "Current Room Status": "Available",
            "Image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2024-08/2%20Bedroom%20Flat-2-min.jpg?itok=L39E8ynN"
           },
           {
            "City": "Manchester",
            "Asset": "Fletcher House",
            "Name": "Flet-L01-F1",
            "Room Type": "Platinum 2 Bed",
            "Term Session": "2024-25 51wks Starting 14/09/2024",
            "Session Booked Status": "Available",
            "Length (Wks)": 51,
            "Rate": 341,
            "Start Date": "14/09/24",
            "End Date": "06/09/25",
            "Term Active Status": "Active",
            "Closed?": "Open",
            "Current Room Status": "Available",
            "Image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2024-08/Flat%20L0%204D-3-min_1.jpg?itok=_GDpP0da"
           },
           {
            "City": "Manchester",
            "Asset": "Fletcher House",
            "Name": "Flet-L01-F2",
            "Room Type": "Platinum 2 Bed",
            "Term Session": "2024-25 51wks Starting 14/09/2024",
            "Session Booked Status": "Available",
            "Length (Wks)": 51,
            "Rate": 341,
            "Start Date": "14/09/24",
            "End Date": "06/09/25",
            "Term Active Status": "Active",
            "Closed?": "Open",
            "Current Room Status": "Available",
            "Image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2024-08/Flat%20L0%204D-3-min_1.jpg?itok=_GDpP0da"
           },
           {
            "City": "Manchester",
            "Asset": "Fletcher House",
            "Name": "Flet-L01-G",
            "Room Type": "Platinum 1 Bed",
            "Term Session": "2024-25 51wks Starting 14/09/2024",
            "Session Booked Status": "Available",
            "Length (Wks)": 51,
            "Rate": 426,
            "Start Date": "14/09/24",
            "End Date": "06/09/25",
            "Term Active Status": "Active",
            "Closed?": "Open",
            "Current Room Status": "Available",
            "Image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2024-08/Flat%20L0%204D-3-min_1.jpg?itok=_GDpP0da"
           },
           {
            "City": "Manchester",
            "Asset": "Fletcher House",
            "Name": "Flet-L02-A",
            "Room Type": "Platinum 1 Bed",
            "Term Session": "2024-25 51wks Starting 14/09/2024",
            "Session Booked Status": "Available",
            "Length (Wks)": 51,
            "Rate": 426,
            "Start Date": "14/09/24",
            "End Date": "06/09/25",
            "Term Active Status": "Active",
            "Closed?": "Open",
            "Current Room Status": "Available",
            "Image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2024-08/Flat%20L0%204D-3-min_1.jpg?itok=_GDpP0da"
           },
           {
            "City": "Manchester",
            "Asset": "Fletcher House",
            "Name": "Flet-L02-C",
            "Room Type": "Platinum 1 Bed",
            "Term Session": "2024-25 51wks Starting 14/09/2024",
            "Session Booked Status": "Available",
            "Length (Wks)": 51,
            "Rate": 426,
            "Start Date": "14/09/24",
            "End Date": "06/09/25",
            "Term Active Status": "Active",
            "Closed?": "Open",
            "Current Room Status": "Available",
            "Image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2024-08/Flat%20L0%204D-3-min_1.jpg?itok=_GDpP0da"
           },
           {
            "City": "Liverpool",
            "Asset": "Great Newton House",
            "Name": "Grea-A04B",
            "Room Type": "Silver 2 Bed Apartment",
            "Term Session": "2024-25 44wks Starting 14/09/2024",
            "Session Booked Status": "Available",
            "Length (Wks)": 44,
            "Rate": 214,
            "Start Date": "14/09/24",
            "End Date": "19/07/25",
            "Term Active Status": "Active",
            "Closed?": "Open",
            "Current Room Status": "Available",
            "Image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-01/iQ-Student-Accommodation-Liverpool-Great-Newton-House-Bedrooms-Gold_En_Suite%287%29_0.jpg?itok=p9J8IM4R"
           },
           {
            "City": "Liverpool",
            "Asset": "Great Newton House",
            "Name": "Grea-A05C",
            "Room Type": "Silver En Suite",
            "Term Session": "2024-25 44wks Starting 14/09/2024",
            "Session Booked Status": "Available",
            "Length (Wks)": 44,
            "Rate": 150,
            "Start Date": "14/09/24",
            "End Date": "19/07/25",
            "Term Active Status": "Active",
            "Closed?": "Open",
            "Current Room Status": "Available",
            "Image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-01/iQ-Student-Accommodation-Liverpool-Great-Newton-House-Bedrooms-Silver_En_Suite_Plus%286%29.jpg?itok=bg2VxG46"
           },
           {
            "City": "Liverpool",
            "Asset": "Great Newton House",
            "Name": "Grea-A10A",
            "Room Type": "Gold 2 Bed Apartment",
            "Term Session": "2024-25 51wks Starting 14/09/2024",
            "Session Booked Status": "Available",
            "Length (Wks)": 51,
            "Rate": 205,
            "Start Date": "14/09/24",
            "End Date": "06/09/25",
            "Term Active Status": "Active",
            "Closed?": "Open",
            "Current Room Status": "Available",
            "Image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-01/iQ-Student-Accommodation-Liverpool-Great-Newton-House-Bedrooms-Silver_En_Suite_Plus%286%29.jpg?itok=bg2VxG46"
           },
           {
            "City": "Liverpool",
            "Asset": "Great Newton House",
            "Name": "Grea-A10B",
            "Room Type": "Gold 2 Bed Apartment",
            "Term Session": "2024-25 51wks Starting 14/09/2024",
            "Session Booked Status": "Available",
            "Length (Wks)": 51,
            "Rate": 205,
            "Start Date": "14/09/24",
            "End Date": "06/09/25",
            "Term Active Status": "Active",
            "Closed?": "Open",
            "Current Room Status": "Available",
            "Image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2022-01/gold%20ensuite.jpg?itok=K6GI-Alm"
           },
           {
            "City": "Liverpool",
            "Asset": "Great Newton House",
            "Name": "Grea-A17A",
            "Room Type": "Gold En Suite",
            "Term Session": "2024-25 51wks Starting 14/09/2024",
            "Session Booked Status": "Available",
            "Length (Wks)": 51,
            "Rate": 151,
            "Start Date": "14/09/24",
            "End Date": "06/09/25",
            "Term Active Status": "Active",
            "Closed?": "Open",
            "Current Room Status": "Available",
            "Image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2022-01/gold%20ensuite.jpg?itok=K6GI-Alm"
           },
           {
            "City": "Liverpool",
            "Asset": "Great Newton House",
            "Name": "Grea-A17E",
            "Room Type": "Gold En Suite",
            "Term Session": "2024-25 51wks Starting 14/09/2024",
            "Session Booked Status": "Available",
            "Length (Wks)": 51,
            "Rate": 151,
            "Start Date": "14/09/24",
            "End Date": "06/09/25",
            "Term Active Status": "Active",
            "Closed?": "Open",
            "Current Room Status": "Available",
            "Image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2022-01/gold%20ensuite.jpg?itok=K6GI-Alm"
           },
           {
            "City": "Liverpool",
            "Asset": "Great Newton House",
            "Name": "Grea-B19A",
            "Room Type": "Gold En Suite Plus",
            "Term Session": "2024-25 44wks Starting 14/09/2024",
            "Session Booked Status": "Available",
            "Length (Wks)": 44,
            "Rate": 168,
            "Start Date": "14/09/24",
            "End Date": "19/07/25",
            "Term Active Status": "Active",
            "Closed?": "Open",
            "Current Room Status": "Available",
            "Image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2020-12/iQ-Student-Accommodation-Liverpool-Great-Newton-House-Bedrooms-Gold_Two_Bed_Apartment%286%29.jpg?itok=Nu3se435"
           },
           {
            "City": "Liverpool",
            "Asset": "Great Newton House",
            "Name": "Grea-B19B",
            "Room Type": "Gold En Suite Plus",
            "Term Session": "2024-25 44wks Starting 14/09/2024",
            "Session Booked Status": "Available",
            "Length (Wks)": 44,
            "Rate": 168,
            "Start Date": "14/09/24",
            "End Date": "19/07/25",
            "Term Active Status": "Active",
            "Closed?": "Open",
            "Current Room Status": "Available",
            "Image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2020-12/iQ-Student-Accommodation-Liverpool-Great-Newton-House-Bedrooms-Gold_Two_Bed_Apartment%286%29.jpg?itok=Nu3se435"
           },
    ],
    "Sheet1":[
     {
      "Property": "Ty Willis House",
      "City": "Bangor"
     },
     {
      "Property": "Broderick House",
      "City": "Birmingham"
     },
     {
      "Property": "Penworks House",
      "City": "Birmingham"
     },
     {
      "Property": "Studios 51",
      "City": "Birmingham"
     },
     {
      "Property": "Arkwright House",
      "City": "Bradford"
     },
     {
      "Property": "Abacus House",
      "City": "Brighton"
     },
     {
      "Property": "Brighton",
      "City": "Brighton"
     },
     {
      "Property": "Sawmills",
      "City": "Brighton"
     },
     {
      "Property": "Bristol",
      "City": "Bristol"
     },
     {
      "Property": "Water Lane House",
      "City": "Bristol"
     },
     {
      "Property": "Weaver Place",
      "City": "Coventry"
     },
     {
      "Property": "Parker House",
      "City": "Dundee"
     },
     {
      "Property": "Elliott House",
      "City": "Edinburgh"
     },
     {
      "Property": "Grove",
      "City": "Edinburgh"
     },
     {
      "Property": "Fountainbridge",
      "City": "Edinburgh "
     },
     {
      "Property": "The Mont",
      "City": "Edinburgh "
     },
     {
      "Property": "Podium",
      "City": "Egham"
     },
     {
      "Property": "The Pad",
      "City": "Egham"
     },
     {
      "Property": "Elgin Place",
      "City": "Glasgow"
     },
     {
      "Property": "Aspley House",
      "City": "Huddersfield"
     },
     {
      "Property": "Castings",
      "City": "Huddersfield"
     },
     {
      "Property": "Little Aspley House",
      "City": "Huddersfield"
     },
     {
      "Property": "Kingston",
      "City": "Kingston"
     },
     {
      "Property": "Altus House",
      "City": "Leeds"
     },
     {
      "Property": "Leeds",
      "City": "Leeds"
     },
     {
      "Property": "Marsden House",
      "City": "Leeds"
     },
     {
      "Property": "Grosvenor House",
      "City": "Leicester"
     },
     {
      "Property": "Opal Court",
      "City": "Leicester"
     },
     {
      "Property": "Hayes Wharf",
      "City": "Lincoln"
     },
     {
      "Property": "Pavilions",
      "City": "Lincoln"
     },
     {
      "Property": "Great Newton House",
      "City": "Liverpool"
     },
     {
      "Property": "Aldgate",
      "City": "London"
     },
     {
      "Property": "Bankside",
      "City": "London"
     },
     {
      "Property": "Bloomsbury",
      "City": "London"
     },
     {
      "Property": "City",
      "City": "London"
     },
     {
      "Property": "East Court",
      "City": "London"
     },
     {
      "Property": "Flinders House",
      "City": "London"
     },
     {
      "Property": "Hammersmith",
      "City": "London"
     },
     {
      "Property": "Haywood House",
      "City": "London"
     },
     {
      "Property": "Highbury",
      "City": "London"
     },
     {
      "Property": "Hoxton",
      "City": "London"
     },
     {
      "Property": "Magenta House",
      "City": "London"
     },
     {
      "Property": "Paris Gardens",
      "City": "London"
     },
     {
      "Property": "Shoreditch",
      "City": "London"
     },
     {
      "Property": "Sterling Court",
      "City": "London"
     },
     {
      "Property": "The Arcade",
      "City": "London"
     },
     {
      "Property": "Tufnell House",
      "City": "London"
     },
     {
      "Property": "Vega",
      "City": "London"
     },
     {
      "Property": "Will Wyatt Court",
      "City": "London"
     },
     {
      "Property": "Fletcher House",
      "City": "Manchester"
     },
     {
      "Property": "Hollingworth House",
      "City": "Manchester"
     },
     {
      "Property": "Kerria Apartments",
      "City": "Manchester"
     },
     {
      "Property": "Lambert & Fairfield House",
      "City": "Manchester"
     },
     {
      "Property": "Manchester Gardens",
      "City": "Manchester"
     },
     {
      "Property": "Wilmslow Park",
      "City": "Manchester"
     },
     {
      "Property": "Collingwood",
      "City": "Newcastle"
     },
     {
      "Property": "Stephenson House",
      "City": "Newcastle"
     },
     {
      "Property": "Exchange",
      "City": "Nottingham"
     },
     {
      "Property": "Nelson Court",
      "City": "Nottingham"
     },
     {
      "Property": "Newland House",
      "City": "Nottingham"
     },
     {
      "Property": "Newtown House",
      "City": "Nottingham"
     },
     {
      "Property": "Signal Place",
      "City": "Nottingham"
     },
     {
      "Property": "Alice House",
      "City": "Oxford"
     },
     {
      "Property": "Astor House",
      "City": "Plymouth"
     },
     {
      "Property": "Kopa",
      "City": "Preston"
     },
     {
      "Property": "Student Quarter",
      "City": "Salford"
     },
     {
      "Property": "Brocco",
      "City": "Sheffield"
     },
     {
      "Property": "Century Square",
      "City": "Sheffield"
     },
     {
      "Property": "Fenton House",
      "City": "Sheffield"
     },
     {
      "Property": "Knight House",
      "City": "Sheffield"
     },
     {
      "Property": "Steel",
      "City": "Sheffield"
     },
     {
      "Property": "Fiveways House",
      "City": "Wolverhampton"
     },
     {
      "Property": "The Brickworks",
      "City": "York"
     }
    ],
}
export default HotelData1;