import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

const profileData = {
  username: 'sophiastyle',
  fullName: 'Sophia Anderson',
  profileImage: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=150&h=150&q=80&c=c',
  postsCount: 248,
  followers: 52300,
  following: 1205,
  bio: 'Fashion & Lifestyle Influencer | Travel Enthusiast | Sustainability Advocate',
  posts: [
    { id: 1, imageUrl: 'https://images.unsplash.com/photo-1515886657613-9f3515b0c78f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=300&q=80' },
    { id: 2, imageUrl: 'https://images.unsplash.com/photo-1529139574466-a303027c1d8b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=300&q=80' },
    { id: 3, imageUrl: 'https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=300&q=80' },
    { id: 4, imageUrl: 'https://images.unsplash.com/photo-1550614000-4895a10e1bfd?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=300&q=80' },
    { id: 5, imageUrl: 'https://images.unsplash.com/photo-1483985988355-763728e1935b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=300&q=80' },
    { id: 6, imageUrl: 'https://images.unsplash.com/photo-1545291730-faff8ca1d4b0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=300&q=80' },
  ]
};

const MyProfile = () => {
  const [activeTab, setActiveTab] = useState('posts');
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className="bg-gray-900 min-h-screen text-white p-4">
      <div className="max-w-3xl mx-auto">
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={handleBack}
          className="text-white p-2 rounded-full transition-colors duration-75 ease-in hover:bg-gray-700"
          aria-label="Back"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
          </svg>
        </motion.button>
        <div className="flex mb-8">
          <motion.div
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
            className="w-1/4 mr-4"
          >
            <div className="w-30 h-30 rounded-full overflow-hidden">
              <img
                src={profileData.profileImage}
                alt={profileData.username}
                className="w-full h-full object-cover"
              />
            </div>
          </motion.div>
          <div className="flex-1">
            <div className="flex items-center mb-4">
              <h1 className="text-xl font-bold mr-4">{profileData.username}</h1>
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="bg-blue-500 text-white px-3 py-1 text-sm rounded-md"
              >
                Edit Profile
              </motion.button>
            </div>
            <div className="flex space-x-4 mb-4 text-sm">
              <span><strong>{profileData.postsCount}</strong> posts</span>
              <span><strong>{profileData.followers}</strong> followers</span>
              <span><strong>{profileData.following}</strong> following</span>
            </div>
            <div className="text-sm">
              <h2 className="font-bold">{profileData.fullName}</h2>
              <p>{profileData.bio}</p>
            </div>
          </div>
        </div>

        <div className="border-t border-gray-700">
          <div className="flex justify-center mt-4">
            {['posts', 'saved', 'tagged'].map((tab) => (
              <motion.button
                key={tab}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className={`mx-2 pb-2 text-sm ${activeTab === tab ? 'border-b-2 border-white' : ''}`}
                onClick={() => setActiveTab(tab)}
              >
                {tab.charAt(0).toUpperCase() + tab.slice(1)}
              </motion.button>
            ))}
          </div>
        </div>

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          className="grid grid-cols-3 gap-2 mt-4"
        >
          {profileData.posts.map((post) => (
            <motion.div
              key={post.id}
              whileHover={{ scale: 1.05 }}
              className="w-full aspect-square rounded-md overflow-hidden"
            >
              <img
                src={post.imageUrl}
                alt={`Post ${post.id}`}
                className="w-full h-full object-cover"
              />
            </motion.div>
          ))}
        </motion.div>
      </div>
    </div>
  );
};

export default MyProfile;