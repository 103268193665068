import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import postsData from './postsData';

const Post = () => {
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [newComment, setNewComment] = useState('');
  const [isFollowing, setIsFollowing] = useState(false);
  const { postId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPost = async () => {
      setLoading(true);
      await new Promise(resolve => setTimeout(resolve, 1000));
      const foundPost = postsData.find(p => p.id === parseInt(postId));
      if (foundPost) {
        setPost(foundPost);
      }
      setLoading(false);
    };

    fetchPost();
  }, [postId]);

  const handleAddComment = (e) => {
    e.preventDefault();
    if (newComment.trim()) {
      setPost(prevPost => ({
        ...prevPost,
        comments: [...prevPost.comments, newComment.trim()]
      }));
      setNewComment('');
    }
  };

  const handleBack = () => {
    navigate('/user-dashboard/explore');
  };

  const handleFollow = () => {
    setIsFollowing(!isFollowing);
  };

  const handleShop = () => {
    // Implement shop functionality here
    console.log('Shop button clicked');
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen bg-gray-900">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (!post) {
    return (
      <div className="flex justify-center items-center h-screen bg-gray-900">
        <p className="text-white text-2xl">Post not found</p>
      </div>
    );
  }

  return (
    <div className="bg-gray-900 min-h-screen p-4">
      <div className="max-w-5xl mx-auto">
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={handleBack}
          className="text-white p-2 m-2 rounded-full transition-colors duration-75 ease-in hover:bg-gray-700"
          aria-label="Back to Feed"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
          </svg>
        </motion.button>
        <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
          <div className="w-full md:w-1/2 md:sticky md:top-0 md:self-start">
            <div className="bg-gray-800 rounded-lg overflow-hidden shadow-lg h-full">
              <motion.img
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
                src={post.imageUrl}
                alt={post.caption}
                className="w-full h-64 md:h-auto object-cover"
              />
            </div>
          </div>
          <div className="w-full md:w-1/2">
            <div className="bg-gray-800 rounded-lg overflow-hidden shadow-lg h-full p-4">
              <motion.h1
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2, duration: 0.5 }}
                className="text-white text-xl font-bold mb-2"
              >
                {post.caption}
              </motion.h1>
              <motion.p
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.3, duration: 0.5 }}
                className="text-gray-400 text-sm mb-3"
              >
                Category: {post.category}
              </motion.p>
              <motion.p
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.4, duration: 0.5 }}
                className="text-gray-400 text-sm mb-3"
              >
                Location: {post.location}
              </motion.p>
              <motion.p
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.5, duration: 0.5 }}
                className="text-gray-400 text-sm mb-3"
              >
                Author: {post.author}
              </motion.p>
              <motion.p
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.6, duration: 0.5 }}
                className="text-gray-400 text-sm mb-3"
              >
                Date: {post.date}
              </motion.p>
              <motion.p
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.7, duration: 0.5 }}
                className="text-gray-300 text-sm mb-4"
              >
                {post.description}
              </motion.p>
              <div className="flex justify-between items-center mb-4">
                <div className="flex space-x-4">
                  <span className="text-blue-400 flex items-center text-sm">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                    </svg>
                    {post.likes} Likes
                  </span>
                  <span className="text-gray-400 flex items-center text-sm">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z" />
                    </svg>
                    {post.shares} Shares
                  </span>
                </div>
                <div className="flex space-x-2">
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={handleFollow}
                    className={`px-3 py-1 rounded text-sm ${isFollowing ? 'bg-gray-600 text-white' : 'bg-blue-600 text-white'}`}
                  >
                    {isFollowing ? 'Following' : 'Follow'}
                  </motion.button>
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={handleShop}
                    className="p-1 bg-green-600 text-white rounded"
                    aria-label="Shop"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z" />
                    </svg>
                  </motion.button>
                </div>
              </div>
              <div className="mb-4 max-h-40 overflow-y-auto">
                <h2 className="text-white text-lg font-semibold mb-2">Comments</h2>
                {post.comments.map((comment, index) => (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.8 + index * 0.1, duration: 0.5 }}
                    className="bg-gray-700 rounded p-2 mb-2"
                  >
                    <p className="text-white text-sm">{comment}</p>
                  </motion.div>
                ))}
              </div>
              <form onSubmit={handleAddComment} className="mt-auto">
                <input
                  type="text"
                  value={newComment}
                  onChange={(e) => setNewComment(e.target.value)}
                  placeholder="Add a comment..."
                  className="w-full bg-gray-700 text-white p-2 rounded mb-2 text-sm"
                />
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  type="submit"
                  className="w-full bg-blue-600 text-white p-2 rounded text-sm"
                >
                  Add Comment
                </motion.button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Post;