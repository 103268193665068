import React, { useState, useEffect } from 'react';
import { GoogleMap, Marker, InfoWindow } from '@react-google-maps/api';

const MapWithMarkers = ({ restaurants, userLocation }) => {
    const [selectedLocation, setSelectedLocation] = useState(null);

    const mapStyles = {
        height: "50vh",
        width: "100%",
    };

    const defaultCenter = userLocation || {
        lat: 53.4808, // Manchester, UK
        lng: -2.2426,
    };

    // Debug: Log restaurant and user location coordinates
    useEffect(() => {
        console.log("Restaurants Data:", restaurants);
        console.log("User Location:", userLocation);
    }, [restaurants, userLocation]);

    return (
        <GoogleMap
            mapContainerStyle={mapStyles}
            zoom={userLocation ? 12 : 7}
            center={defaultCenter}
        >
            {restaurants.map((restaurant, index) => {
                // Debug: Log each restaurant's latitude and longitude
                console.log(`Restaurant ${index}:`, restaurant.name, 
                    "Lat:", restaurant.latitude, 
                    "Lng:", restaurant.longitude
                );

                return (
                    <Marker
                        key={index}
                        position={{ lat: restaurant.latitude, lng: restaurant.longitude }}
                        onClick={() => setSelectedLocation(restaurant)}
                    />
                );
            })}

            {userLocation && (
                <Marker
                    position={userLocation}
                    icon={{
                        url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png",
                        scaledSize: new window.google.maps.Size(40, 40),
                    }}
                    onClick={() => setSelectedLocation({ 
                        name: 'Your Location', 
                        latitude: userLocation.lat, 
                        longitude: userLocation.lng 
                    })}
                />
            )}

            {selectedLocation && (
                <InfoWindow
                    position={{
                        lat: selectedLocation.latitude,
                        lng: selectedLocation.longitude,
                    }}
                    onCloseClick={() => setSelectedLocation(null)}
                >
                    <div>
                        <strong>{selectedLocation.name}</strong>
                        {selectedLocation.address && <p>{selectedLocation.address}</p>}
                        <a
                            href={`https://www.google.com/maps?q=${encodeURIComponent(selectedLocation.name)}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: 'blue', textDecoration: 'underline' }}
                        >
                            View in Google Maps
                        </a>
                    </div>
                </InfoWindow>
            )}
        </GoogleMap>
    );
};

export default MapWithMarkers;
