import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import jobs1 from './updatedJobs';
import jobUpdate from './fulljobs_update';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faMapMarkerAlt, faBriefcase, faUserFriends, faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const TempJobs = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filterJobType, setFilterJobType] = useState('All');
  const [filterLocation, setFilterLocation] = useState('All');
  const [selectedJob, setSelectedJob] = useState(null); // Start with null
  const [showJobDetails, setShowJobDetails] = useState(false);

  // Memoize the filtered jobs so that filtering only happens when searchTerm or filters change
  const filteredJobs = useMemo(() => {
    return jobUpdate.filter((job) => {
      if (!job) return false;

      const jobTitle = job.job?.toLowerCase() ?? '';
      const company = job.company?.toLowerCase() ?? '';
      const jobType = job.job_type ?? '';
      const location = job.location ?? '';

      const matchesSearchTerm = searchTerm === '' ||
        jobTitle.includes(searchTerm.toLowerCase()) ||
        company.includes(searchTerm.toLowerCase());

      const matchesJobType = filterJobType === 'All' || jobType === filterJobType;
      const matchesLocation = filterLocation === 'All' || location === filterLocation;

      return matchesSearchTerm && matchesJobType && matchesLocation;
    });
  }, [searchTerm, filterJobType, filterLocation]);

  // Set the first job as selected when the component mounts
  useEffect(() => {
    if (filteredJobs.length > 0) {
      setSelectedJob(filteredJobs[0]); // Automatically select the first job
      setShowJobDetails(true); // Show the details of the first job
    }
  }, [filteredJobs]);

  const handleSearchChange = useCallback((e) => {
    setSearchTerm(e.target.value);
  }, []);

  const handleJobTypeChange = useCallback((e) => {
    setFilterJobType(e.target.value);
  }, []);

  const handleLocationChange = useCallback((e) => {
    setFilterLocation(e.target.value);
  }, []);

  const handleJobClick = useCallback((job) => {
    setSelectedJob(job);
    setShowJobDetails(true);
  }, []);

  const handleBackClick = useCallback(() => {
    setShowJobDetails(false);
  }, []);

  return (
    <div className="bg-gray-900 min-h-screen text-white">
      {/* Search and Filter Section */}
      <div className="bg-gray-800 p-4 top-0 z-10">
        <div className="max-w-6xl mx-auto flex flex-col md:flex-row gap-4">
          <input
            type="text"
            placeholder="Search jobs..."
            value={searchTerm}
            onChange={handleSearchChange}
            className="flex-1 p-2 bg-gray-700 text-white rounded focus:outline-none focus:ring-2 focus:ring-blue-400"
          />
          <select
            value={filterJobType}
            onChange={handleJobTypeChange}
            className="md:w-48 p-2 bg-gray-700 text-white rounded focus:outline-none"
          >
            <option value="All">All Job Types</option>
            <option value="Internship">Internship</option>

            <option value="part-time">Part time</option>
            <option value="Full-time">Full time</option>
          </select>
          <select
            value={filterLocation}
            onChange={handleLocationChange}
            className="md:w-48 p-2 bg-gray-700 text-white rounded focus:outline-none"
          >
            <option value="All">All Locations</option>
            <option value="United Kingdom">United Kingdom</option>
            <option value="United States">United States</option>
          </select>
        </div>
      </div>

      {/* Job List and Details */}
      <div className="flex flex-col h-[calc(100vh-80px)]">
        {/* Mobile View */}
        <div className="md:hidden h-full">
          {showJobDetails && selectedJob ? (
            <JobDetailView job={selectedJob} onBack={handleBackClick} />
          ) : (
            <JobListView jobs={filteredJobs} onJobClick={handleJobClick} />
          )}
        </div>

        {/* Desktop View */}
        <div className="hidden md:flex h-full">
          <div className="w-1/3 border-r border-gray-700">
            <JobListView jobs={filteredJobs} onJobClick={handleJobClick} />
          </div>
          <div className="w-2/3">
            {selectedJob ? (
              <JobDetailView job={selectedJob} />
            ) : (
              <div className="h-full flex items-center justify-center">
                <p className="text-lg text-gray-400">Select a job to view details</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const JobListView = ({ jobs, onJobClick }) => (
  <div className="w-full bg-gray-900 p-4 h-full overflow-y-auto">
    <div className="max-w-2xl mx-auto">
      <h2 className="text-xl font-bold mb-4">Job Listings</h2>
      {jobs.length > 0 ? (
        <ul className="space-y-4 w-full pl-0">
          {jobs.map((job, index) => (
            <li
              key={index}
              className="p-4 bg-gray-800 rounded cursor-pointer hover:bg-gray-700 transition-colors duration-200"
              onClick={() => onJobClick(job)}
            >
              <div className="flex items-start">
                <img src={job.image_url || ''} alt={job.job || ''} className="w-10 h-10 rounded mr-4" />
                <div>
                  <h3 className="font-bold text-lg">{job.job || 'Untitled Job'}</h3>
                  <p className="text-md">{job.company || 'Company not specified'}</p>
                  <p className="text-md">{job.location || 'Location not specified'}</p>
                </div>
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <p className="text-gray-400">No jobs found</p>
      )}
    </div>
  </div>
);

const JobDetailView = ({ job, onBack }) => (
  <div className="w-full bg-gray-900 h-full overflow-y-auto">
    {onBack && (
      <button onClick={onBack} className="p-4 text-blue-400 flex items-center">
        Back to listings
      </button>
    )}
    <div className="max-w-3xl mx-auto">
      <div className="bg-gray-800 p-6 rounded-lg shadow-lg m-4">
        <h2 className="text-3xl font-bold mb-4 text-blue-400">{job.job}</h2>
        <div className="mb-4">
          <p>
            <strong>Company:</strong> {job.company}
          </p>
          <p>
            <strong>Location:</strong> {job.location}
          </p>
          <p>
            <strong>Job Type:</strong> {job.job_type}
          </p>
          <p>
            <strong>Employment Type:</strong> {job.employement_type}
          </p>
        </div>
        <div className="mb-4">
          <p className="flex items-center mb-2">
            <strong className="text-lg">Applicants:</strong>
            <span className="ml-2 text-gray-300">{job.no_of_applicants}</span>
          </p>
          <p className="text-gray-300 mb-4">
            <strong className="text-lg">Description:</strong>
            <span className="ml-2 block mt-1 text-sm text-gray-400">{job.description}</span>
          </p>
        </div>
        <a
          href={job.job_url}
          target="_blank"
          rel="noopener noreferrer"
          className="bg-blue-600 hover:bg-blue-500 text-white py-2 px-4 rounded-lg transition-colors duration-200 block text-center"
        >
          Apply Here
        </a>
      </div>
    </div>
  </div>
);

export default TempJobs;
