const postsData =  [{ 
    id: 1, 
    imageUrl: 'https://images.unsplash.com/photo-1515886657613-9f3515b0c78f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=800&q=80', 
    caption: 'New summer collection', 
    category: 'Fashion', 
    comments: [], 
    likes: 0, 
    shares: 0,
    location: 'London, UK',
    author: 'Fashionista123',
    date: '2023-06-15',
    description: 'Check out our latest summer collection featuring vibrant colors and breezy fabrics!'
  },
  { 
    id: 2, 
    imageUrl: 'https://images.unsplash.com/photo-1596462502278-27bfdc403348?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=800&q=80', 
    caption: 'Must-have skincare products', 
    category: 'Beauty', 
    comments: [], 
    likes: 0, 
    shares: 0,
    location: 'Paris, France',
    author: 'BeautyGuru',
    date: '2023-06-14',
    description: 'Discover the ultimate skincare routine with these essential products for glowing skin!'
  },
  { 
    id: 3, 
    imageUrl: 'https://images.unsplash.com/photo-1550009158-9ebf69173e03?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=800&q=80', 
    caption: 'Latest gadget review', 
    category: 'Tech', 
    comments: [], 
    likes: 0, 
    shares: 0,
    location: 'San Francisco, USA',
    author: 'TechEnthusiast',
    date: '2023-06-13',
    description: 'In-depth review of the newest smartphone on the market. Is it worth the hype?'
  },
  { 
    id: 4, 
    imageUrl: 'https://images.unsplash.com/photo-1517836357463-d25dfeac3438?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=800&q=80', 
    caption: 'Home workout routine', 
    category: 'Fitness', 
    comments: [], 
    likes: 0, 
    shares: 0,
    location: 'Sydney, Australia',
    author: 'FitnessFanatic',
    date: '2023-06-12',
    description: 'Try this effective 30-minute home workout routine to stay fit and healthy!'
  },
  { 
    id: 5, 
    imageUrl: 'https://images.unsplash.com/photo-1522199755839-a2bacb67c546?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=800&q=80', 
    caption: 'Healthy breakfast ideas', 
    category: 'Lifestyle', 
    comments: [], 
    likes: 0, 
    shares: 0,
    location: 'Berlin, Germany',
    author: 'HealthyLiving',
    date: '2023-06-11',
    description: 'Start your day right with these nutritious and delicious breakfast recipes!'
  },
  { 
    id: 6, 
    imageUrl: 'https://images.unsplash.com/photo-1483985988355-763728e1935b?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=800&q=80', 
    caption: 'Trendy accessories', 
    category: 'Fashion', 
    comments: [], 
    likes: 0, 
    shares: 0,
    location: 'Milan, Italy',
    author: 'StyleIcon',
    date: '2023-06-10',
    description: 'Elevate your outfit with these must-have accessories for the season!'
  },
];

export default postsData;