import React, { useState, useEffect } from 'react';
import HotelData1 from '../../Data/HotelData1';
import _ from 'lodash';
import room from '../../../assets/img/room.jpg';

const HotelBooking = () => {
  const [exportData, setExportData] = useState([]);
  const [filterCity, setFilterCity] = useState(''); // Default to all cities
  const [filterAvailability, setFilterAvailability] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [uniqueCities, setUniqueCities] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [minPrice, setMinPrice] = useState('');
  const [maxPrice, setMaxPrice] = useState('');

  const itemsPerPage = 8;

  useEffect(() => {
    const loadData = () => {
      try {
        const exportSheet = HotelData1.Export;
        if (!exportSheet) {
          console.error('Export sheet is null or undefined');
          return;
        }

        // Filter out objects with any null values
        const filteredExportSheet = exportSheet.filter(obj =>
          Object.values(obj || {}).every(value => value !== null)
        );
        setExportData(filteredExportSheet);

        // Get unique cities from the export data
        const cities = _.uniq(filteredExportSheet.map(item => item.City.trim())); // Trim any extra spaces
        setUniqueCities(cities);
      } catch (error) {
        console.error('Error loading data:', error);
      }
    };

    loadData();
  }, []);

  useEffect(() => {
    const applyFilters = () => {
      let filtered = exportData || [];
  
      // City filter logic
      if (filterCity && filterCity !== '') {
        filtered = filtered.filter(item => {
          if (item && item.City) {
            const normalizedCity = item.City.toLowerCase().trim();
            const selectedCity = filterCity.toLowerCase().trim();
            return normalizedCity === selectedCity;
          }
          return false;
        });
      }
  
      // Availability filter
      if (filterAvailability) {
        filtered = filtered.filter(item => item && item['Session Booked Status'] === filterAvailability);
      }
  
      // Search term filter
      if (searchTerm) {
        filtered = filtered.filter(item =>
          Object.values(item).some(val =>
            val && val.toString().toLowerCase().includes(searchTerm.toLowerCase())
          )
        );
      }
  
      // Price range filter
      if (minPrice !== '') {
        filtered = filtered.filter(item => item && item.Rate >= parseInt(minPrice));
      }
      if (maxPrice !== '') {
        filtered = filtered.filter(item => item && item.Rate <= parseInt(maxPrice));
      }
  
      console.log('Filtered Data Before Setting State:', filtered); // Log filtered data
      setFilteredData(filtered);
    };
  
    applyFilters();
  }, [exportData, filterCity, filterAvailability, searchTerm, minPrice, maxPrice]);
  

    // Pagination logic
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  // Log the current items
  useEffect(() => {
    console.log('Current Items on This Page:', currentItems); // Log current page's items
  }, [currentItems]);
  


  const handlePrevPage = () => {
    setCurrentPage(prev => Math.max(prev - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage(prev => Math.min(prev + 1, totalPages));
  };

  const handleBuyClick = (eventId) => {
    console.log(`Buy clicked for event ID ${eventId}`);
  };

  return (
    <div className="bg-gray-900 text-gray-100 min-h-screen">
      {/* Hero Section */}
      <div className="relative bg-gray-800 py-16">
        <img
          src={room}
          alt="Hotel Room"
          className="absolute inset-0 w-full h-full object-cover opacity-30"
        />
        <div className="relative z-10 container mx-auto px-4">
          <h1 className="text-4xl sm:text-5xl font-bold text-white mb-4">Find Your Ideal Accommodation</h1>
          <p className="text-xl text-gray-300">Discover comfortable stays tailored to your needs.</p>
        </div>
      </div>

      {/* Filters and Results */}
      <div className="container mx-auto py-8 px-4">
        <div className="bg-gray-800 rounded-lg p-6 mb-8">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
            <input
              type="text"
              placeholder="Search..."
              onChange={e => setSearchTerm(e.target.value)}
              className="bg-gray-700 text-gray-100 p-3 rounded-md w-full"
            />
            <select
              onChange={e => setFilterCity(e.target.value)}
              className="bg-gray-700 text-gray-100 p-3 rounded-md w-full"
            >
              <option value="">All Cities</option> {/* Default to All Cities */}
              {uniqueCities.map((city, index) => (
                <option key={index} value={city}>{city}</option>
              ))}
            </select>
            <select
              onChange={e => setFilterAvailability(e.target.value)}
              className="bg-gray-700 text-gray-100 p-3 rounded-md w-full"
            >
              <option value="">All Availability</option>
              <option value="Available">Available</option>
              <option value="Booked">Booked</option>
            </select>
            <div className="flex flex-col">
              <label htmlFor="maxPrice" className="text-gray-300 mb-2">
                Max Price: £{maxPrice}
              </label>
              <input
                type="range"
                id="maxPrice"
                min={0}
                max={300}
                value={maxPrice}
                onChange={e => setMaxPrice(e.target.value)}
                className="w-full h-2 bg-gray-600 rounded-lg appearance-none cursor-pointer"
              />
            </div>
          </div>
        </div>

        {/* Hotel Cards */}
        {filteredData.length === 0 ? (
          <div className="text-center text-gray-400 mt-8">
            <p>No rooms available matching your criteria.</p>
          </div>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
            {currentItems.map((item, index) => (
              <div key={index} className="bg-gray-800 rounded-lg overflow-hidden shadow-lg">
                <img
                  src={item.Image_url}
                  alt="Hotel"
                  className="w-full h-48 object-cover"
                />
                <div className="p-6">
                  <h2 className="text-xl font-semibold mb-2">£{item.Rate} / night</h2>
                  <p className="text-gray-400 mb-2">Location: {item.City}</p>
                  <p className="text-gray-400 mb-4">Room Type: {item['Room Type']}</p>
                  <button
                    className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 transition duration-300"
                    onClick={() => handleBuyClick(item.eventId)}
                  >
                    View Details
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}

        <div className="flex justify-center items-center space-x-4 mt-8">
          <button
            onClick={handlePrevPage}
            disabled={currentPage === 1}
            className="bg-gray-700 text-gray-300 py-2 px-4 rounded-md disabled:opacity-50 hover:bg-gray-600"
          >
            Previous
          </button>
          <span className="text-gray-400">
            Page {currentPage} of {totalPages}
          </span>
          <button
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
            className="bg-gray-700 text-gray-300 py-2 px-4 rounded-md disabled:opacity-50 hover:bg-gray-600"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default HotelBooking;
