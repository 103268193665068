import React ,{useEffect}from 'react';
import { useNavigate } from 'react-router-dom';
import {FaArrowLeft} from 'react-icons/fa'
const Itenary = () => {
  // Sample data for the itinerary
  const navigate = useNavigate();
  const itineraryData = [
    {
      time: '1:30 PM',
      name: 'Alto by San Carlos',
      image: 'https://via.placeholder.com/100x70', // Replace with actual images
    },
    {
      time: '2:30 PM',
      name: 'O2 Boom Bar',
      image: 'https://via.placeholder.com/100x70', // Replace with actual images
    },
    {
      time: '4:00 PM',
      name: 'Pelican Stairs',
      image: 'https://via.placeholder.com/100x70', // Replace with actual images
    },
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="dark text-white min-h-screen flex flex-col items-center py-8 px-4 relative">
      <button onClick={() => window.history.back()} className="absolute top-4 left-4 flex items-center space-x-2 text-white text-lg font-semibold">
        <FaArrowLeft />
      </button>
      <h1 className="text-3xl font-bold mb-10">Create A Relive Experience</h1>

      {/* Itinerary Section */}
      <div className="flex justify-between w-full max-w-3xl space-x-8">
        {/* Left Column: Time and Events */}
        <div className="flex flex-col space-y-6">
          <h2 className="text-xl font-semibold mb-4">Itinerary</h2>
          {itineraryData.map((item, index) => (
            <div key={index} className="flex items-center space-x-4">
              <div className="text-lg font-semibold">{item.time}</div>
              <div className="w-1 h-full border-l-2 border-dashed border-gray-500 mx-2"></div>
              <div className="text-lg font-medium">{item.name}</div>
            </div>
          ))}
        </div>

        {/* Right Column: Clickable Images */}
        <div className="flex flex-col space-y-6">
          {itineraryData.map((item, index) => (
            <div
              key={index}
              className="relative group cursor-pointer"
            >
              <img
                src={item.image}
                alt={item.name}
                className="rounded-lg shadow-lg"
              />
              <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                <span className="text-white font-semibold">CLICK TO VIEW</span>
              </div>
            </div>
          ))}
        </div>
      </div>

       {/* Go to Itinerary Button */}
       <button onClick={() => navigate('/user-dashboard/reliv/create/post')} className="bg-green-500 px-4 py-2 rounded text-black font-bold">
       CREATE A RELIV POST
      </button>
    </div>
  );
};

export default Itenary;
