import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import UniData from './UniData';

const University = () => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const universitiesPerPage = 8; // Number of universities per page
  const totalPages = Math.ceil(UniData.length / universitiesPerPage);
  const [searchTerm, setSearchTerm] = useState('');

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const filteredUniversities = UniData.filter(university => 
    university.university.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="bg-gray-900 min-h-screen md:p-8">
      {/* Hero Section */}
      <div className="relative mb-8">
        <img
          src="https://images.unsplash.com/photo-1541339907198-e08756dedf3f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1200&h=400&q=80"
          srcSet="https://images.unsplash.com/photo-1541339907198-e08756dedf3f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=600&h=200&q=80 600w,
                    https://images.unsplash.com/photo-1541339907198-e08756dedf3f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1200&h=400&q=80 1200w"
          sizes="(max-width: 600px) 100vw, 1200px"
          loading="lazy"
          alt="Famous Universities Banner"
          className="w-full h-48 sm:h-64 object-cover rounded-lg"
        />
        <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col justify-center items-center text-white p-4 rounded-lg">
          <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold mb-2 sm:mb-4 text-center">
            Explore Your Dream University
          </h1>
          <p className="text-sm sm:text-base md:text-lg text-center mb-4 sm:mb-6 hidden sm:block">
            Discover the rich history and academic excellence of institutions like Oxford and Cambridge. From the
            picturesque landscapes of Scotland to the vibrant city life in London, the UK offers a diverse range of
            educational experiences to pursue your aspirations.
          </p>
          <button className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-300 text-sm sm:text-base">
            Explore
          </button>
        </div>
      </div>

      <div className="flex justify-between items-center mb-6">
        <h2 className="text-3xl font-bold text-white">Popular Universities</h2>
        <input 
          type="text" 
          value={searchTerm} 
          onChange={(e) => setSearchTerm(e.target.value)} 
          placeholder="Search universities..." 
          className="bg-gray-800 text-white py-2 px-4 rounded-md"
        />
      </div>

      {/* Universities Grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
        {filteredUniversities.slice((currentPage - 1) * universitiesPerPage, currentPage * universitiesPerPage).map(
          (university, index) => (
            <div
              key={index}
              className="bg-gray-800 rounded-lg overflow-hidden shadow-lg hover:shadow-xl transition-shadow duration-300"
            >
              <img
                src={university.logo_url}
                alt={university.university}
                loading="lazy"
                className="w-full h-48 object-cover"
              />
              <div className="p-4">
                <h3 className="text-xl font-semibold mb-2 text-white">{university.university}</h3>
                <p className="text-sm text-gray-300 mb-2">Global Rank: {university.global_rank}</p>
                <p className="text-sm text-gray-300 mb-3">
                  {university.description.length > 100
                    ? `${university.description.substring(0, 100)}...`
                    : university.description}
                </p>
                {university.description.length > 100 && (
                  <button
                    className="text-sm text-blue-400 hover:text-blue-300 transition-colors duration-200"
                    onClick={() => navigate(`${university.id}`)}
                  >
                    Read more
                  </button>
                )}
              </div>
            </div>
          )
        )}
      </div>

      {/* Pagination */}
      <div className="flex justify-center items-center space-x-4 mt-8">
        <button
          onClick={handlePrevPage}
          disabled={currentPage === 1}
          className="bg-gray-700 text-gray-300 py-2 px-4 rounded-md disabled:opacity-50 hover:bg-gray-600"
        >
          Previous
        </button>
        <span className="text-gray-400">
          Page {currentPage} of {totalPages}
        </span>
        <button
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
          className="bg-gray-700 text-gray-300 py-2 px-4 rounded-md disabled:opacity-50 hover:bg-gray-600"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default University;
