import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaHeart, FaComment, FaShare, FaBookmark, FaYelp, FaPlus, FaBell } from 'react-icons/fa';
import Video1 from '../../../../assets/img/video1.mp4';
import Video2 from '../../../../assets/img/video2.mp4';
import Video3 from '../../../../assets/img/video3.mp4';
import Video4 from '../../../../assets/img/video4.mp4';
import globe1 from '../../../../assets/img/globe2.png';

const categoriesData = [
  { id: 1, name: 'For You' },
  { id: 2, name: 'Trending' },
  { id: 3, name: 'Following' },
];

const RelivHome = () => {
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [videos, setVideos] = useState([]);
  const videoRefs = useRef([]);
  const containerRef = useRef(null);
  const navigate = useNavigate();

  const importedVideos = [
    { id: 1, url: Video1, caption: 'Delicious meal', likes: 1200, comments: 89, user: 'FoodLover123' },
    { id: 2, url: Video2, caption: 'Cooking masterpiece', likes: 980, comments: 56, user: 'ChefExtraordinaire' },
    { id: 3, url: Video3, caption: 'Gourmet dish', likes: 1500, comments: 120, user: 'CulinaryArtist' },
    { id: 4, url: Video4, caption: 'Traveling vlog', likes: 2000, comments: 190, user: 'TravelerJoe' },
  ];

  useEffect(() => {
    setVideos(importedVideos);
  }, []);

  const handleIntersection = useCallback(
    (entries) => {
      entries.forEach((entry) => {
        const index = Number(entry.target.getAttribute('data-index'));
        const video = videoRefs.current[index];
        if (video) {
          if (entry.isIntersecting) {
            if (video.paused) {
              video.play().catch((error) => console.warn('Error trying to play video:', error));
            }
            setCurrentVideoIndex(index);
          } else {
            video.pause();
          }
        }
      });
    },
    []
  );

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, { threshold: 0.75 });

    videoRefs.current.forEach((video) => {
      if (video) {
        observer.observe(video);
      }
    });

    return () => {
      videoRefs.current.forEach((video) => {
        if (video) {
          observer.unobserve(video);
        }
      });
    };
  }, [videos, handleIntersection]);

  const IconButton = ({ Icon, label, count }) => (
    <div className="flex flex-col items-center">
      <button className="text-white p-2 rounded-full bg-gray-800 bg-opacity-50 hover:bg-opacity-75 transition-all duration-200">
        <Icon size={24} />
      </button>
      {count !== undefined && <span className="text-white text-xs mt-1">{count}</span>}
      <span className="text-white text-xs mt-1">{label}</span>
    </div>
  );

  return (
    <div className="bg-gray-900 min-h-screen flex flex-col">
    {/* Top Navigation with Tabs */}
    <div className="flex justify-between items-center px-4 py-3 bg-gray-900">
      <div className="flex items-center space-x-2">
        <h1 className="text-white text-2xl font-bold">Reliv</h1>
        <FaYelp className="text-white text-2xl" />
      </div>
  
      {/* Center-aligned Tabs */}
      <div className="flex space-x-4">
        {categoriesData.map((category) => (
          <button
            key={category.id}
            className="text-white bg-gray-800 px-4 py-2 rounded-full focus:outline-none"
          >
            {category.name}
          </button>
        ))}
      </div>
  
      {/* Right-aligned Options */}
      <div className="flex items-center space-x-4">
        <FaPlus
          className="text-white text-xl cursor-pointer"
          onClick={() => navigate('/user-dashboard/reliv/create')}
        />
        <FaBell className="text-white text-xl cursor-pointer relative">
          <span className="absolute top-0 right-0 bg-red-500 text-xs rounded-full w-4 h-4 flex items-center justify-center">
            2
          </span>
        </FaBell>
                  <img 
                    src={globe1} 
                    alt="WorldLynk Small Icon" 
                    className="w-8 h-8 cursor-pointer" // Adjust size as needed
                    onClick={()=>{navigate('/user-dashboard/reliv/myworlds')}}
                  />
                </div>
    </div>
  
    {/* Main Content Section */}
    <div className="flex-1 flex relative">
      {/* Left Vertical Options */}
      <div className="absolute left-6 top-20 space-y-4">
  {['Relive', 'Live'].map((option, idx) => (
    <div key={idx} className="flex items-center space-x-3">
      {/* Colored Indicator */}
      <div
        className={`w-2 h-8 rounded-l-full ${
          option === 'Relive'
            ? 'bg-orange-500'
            : option === 'Live'
            ? 'bg-gray-800'
            : 'bg-gray-900'
        }`}
      ></div>

      {/* Option Label */}
      <span
        className={`text-white uppercase text-xl font-bold ${
          option === 'Relive' ? 'text-orange-500' : 'text-gray-300'
        }`}
      >
        {option}
      </span>
    </div>
  ))}
</div>

  
      {/* Video Section (9:16 ratio) */}
      <div
        ref={containerRef}
        className="flex justify-center items-center w-full"
      >
        <div className="relative w-full max-w-[360px] h-[640px] overflow-y-scroll snap-y snap-mandatory scrollbar-hide bg-black">
          {videos.map((video, index) => (
            <div key={video.id} className="relative h-[640px] snap-start">
              <video
                ref={(el) => (videoRefs.current[index] = el)}
                data-index={index}
                src={video.url}
                className="w-full h-full object-cover"
                loop
                muted
                playsInline
              />
              <div className="absolute bottom-0 left-0 right-0 p-4 bg-gradient-to-t from-black to-transparent">
                <p className="text-white text-lg mb-2">{video.caption}</p>
                <p className="text-white text-sm font-semibold">@{video.user}</p>
              </div>
              <div className="absolute right-4 bottom-20 flex flex-col space-y-6">
  <IconButton Icon={FaHeart} label="Like" count={video.likes} />

  {/* FaYelp Button with working onClick */}
  <div onClick={() => navigate('/user-dashboard/reliv/book')}>
    <IconButton Icon={FaYelp} label="Reliv" />
  </div>

  <IconButton Icon={FaComment} label="Comment" count={video.comments} />
  <IconButton Icon={FaShare} label="Share" />
  <IconButton Icon={FaBookmark} label="Save" />
</div>

            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
  
  );
};

export default RelivHome;
