import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './auth/userProvider/AuthProvider';
import StripeProvider from './components/CheckOutForm/StripeProvider';
import { Provider } from 'react-redux';  // Import Provider from react-redux
import store from './redux/store';       // Import the Redux store

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    {/* Wrap the entire app with Provider and pass the Redux store */}
    <Provider store={store}> 
      <AuthProvider>
        <StripeProvider>
          <App />
        </StripeProvider>
      </AuthProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
reportWebVitals();
