import React, { useEffect, useState } from 'react';
import UniData from './UniData'; // Assume UniData contains university data
import courseData from './courses'; // Imported course data (array of objects)
import { useParams } from 'react-router-dom';

const PreviewUniversity = () => {
  const { id } = useParams();
  const [university, setUniversity] = useState(null);
  const [universityCourses, setUniversityCourses] = useState([]);
  const [activeTab, setActiveTab] = useState('info');

  useEffect(() => {
    const foundUniversity = UniData.find((university) => university.id === parseInt(id));
    setUniversity(foundUniversity);

    const filteredCourses = courseData.find((course) => course.id === parseInt(id));
    if (filteredCourses) {
      setUniversityCourses(filteredCourses.courses);
    } else {
      setUniversityCourses([]);
    }
  }, [id]);

  if (!university) return <div className="text-center text-white">University not found</div>;

  return (
    <div className="text-gray-200 bg-gray-900 min-h-screen font-sans">
      {/* Hero Section */}
      <div
        className="hero-section h-80 bg-cover bg-center flex items-center justify-center relative"
        style={{ backgroundImage: `url(${university.bg_image})` }}
      >
        <div className="hero-content text-center bg-gray-800 bg-opacity-60 p-6 rounded-md shadow-lg">
          <h1 className="text-5xl font-extrabold text-white tracking-wide">{university.university}</h1>
          <p className="text-lg text-gray-300 mt-2">{university.tagline}</p>
        </div>
        <div className="absolute bottom-4 left-4">
          <img src={university.logo_url} alt="University Logo" className="w-24 h-24 object-cover rounded-full shadow-lg" />
        </div>
      </div>

      {/* Sub Navigation */}
      <div className="subnav flex justify-center space-x-6 py-4 border-b border-gray-700 mt-4">
        <button
          onClick={() => setActiveTab('info')}
          className={`px-6 py-2 text-lg font-semibold rounded transition-colors ${
            activeTab === 'info' ? 'bg-blue-600 text-white' : 'bg-gray-800 hover:bg-gray-700'
          }`}
        >
          Info
        </button>
        <button
          onClick={() => setActiveTab('courses')}
          className={`px-6 py-2 text-lg font-semibold rounded transition-colors ${
            activeTab === 'courses' ? 'bg-blue-600 text-white' : 'bg-gray-800 hover:bg-gray-700'
          }`}
        >
          Courses
        </button>
      </div>

      {/* Content Section */}
      <div className="content mt-8 px-6 md:px-12 lg:px-24">
        {activeTab === 'info' && (
          <div className="info-section text-gray-300">
            <p className="text-center text-lg leading-relaxed mb-6">{university.description}</p>
            <div className="mt-6 space-y-4">
              <h3 className="text-xl font-semibold mb-4 text-white">University Details</h3>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <p><strong>Global Rank:</strong> {university.global_rank}</p>
                <p><strong>Moto:</strong> {university.moto}</p>
                <p><strong>Address:</strong> {university.address}</p>
                <p><strong>Contact Number:</strong> {university.contact_number}</p>
                <p><strong>Programs Offered:</strong> {university.programms_offered}</p>
                <p><strong>Affiliations and Memberships:</strong> {university.affiliations_and_memberships}</p>
                <p><strong>Undergraduate Fee (Local):</strong> {university.ug_fee_local}</p>
                <p><strong>Undergraduate Fee (International):</strong> {university.ug_fee_international}</p>
                <p><strong>Postgraduate Fee (Local):</strong> {university.pg_fee_local}</p>
                <p><strong>Postgraduate Fee (International):</strong> {university.pg_fee_international}</p>
                <p><strong>Admission Rate:</strong> {university.admission_rate}</p>
                <p><strong>Housing Facility:</strong> {university.housing_facility}</p>
                <p><strong>Library:</strong> {university.library}</p>
                <p><strong>Admission Details:</strong> {university.admission_details}</p>
                <p><strong>University Services:</strong> {university.university_services}</p>
                <p><strong>Staff:</strong> {university.staff}</p>
              </div>

              <h4 className="mt-8 text-xl font-semibold text-white">Useful Links</h4>
              <div className="mt-2 flex flex-col space-y-2">
                <a href={university.official_website} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:text-blue-700">
                  Official Website
                </a>
                <a href={university.twitter_url} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:text-blue-700">
                  Twitter
                </a>
                <a href={university.linkedin} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:text-blue-700">
                  LinkedIn
                </a>
              </div>
            </div>
          </div>
        )}

        {activeTab === 'courses' && (
          <div className="courses-section">
            <h2 className="text-2xl font-bold text-white mb-6">Available Courses</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {universityCourses.length > 0 ? (
                universityCourses.map((course, index) => (
                  <div
                    key={index}
                    className="bg-gray-800 p-6 rounded-lg shadow-md hover:bg-gray-700 transition-all"
                  >
                    <p className="text-lg font-medium text-gray-100">{course}</p>
                  </div>
                ))
              ) : (
                <p className="text-lg text-center text-gray-400">No courses available for this university.</p>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PreviewUniversity;
