import React from 'react';
import {FaArrowLeft} from 'react-icons/fa'

const RelivAndBooking = () => {
  const itineraryData = [
    {
      time: '1:30 PM',
      name: 'Prada Café',
      description:
        'Order: Truffle pasta, rose wine, tiramisu and espresso\nTip: Sit at the outside tables',
      image: 'https://via.placeholder.com/100x100',
    },
    {
      time: '3:00 PM',
      name: 'Hyde Park Paddle Boating',
      description: 'Tip: Visit on weekdays as it is very busy on weekends',
      image: 'https://via.placeholder.com/100x100',
    },
    {
      time: '5:00 PM',
      name: 'Harrods',
      description: 'Purchases: Gucci Marmont Mini Bag and Armani Si Perfume',
      image: 'https://via.placeholder.com/100x100',
    },
  ];

  const recommendations = [
    {
      name: 'Pascor',
      image: 'https://via.placeholder.com/150x150',
      link: 'https://www.google.com',
    },
    {
      name: 'Natural History Museum',
      image: 'https://via.placeholder.com/150x150',
      link: 'https://www.nhm.ac.uk',
    },
    {
      name: 'Westfield White City',
      image: 'https://via.placeholder.com/150x150',
      link: 'https://uk.westfield.com/london',
    },
    {
        name: 'Westfield White City',
        image: 'https://via.placeholder.com/150x150',
        link: 'https://uk.westfield.com/london',
      },
    //   {
    //     name: 'Westfield White City',
    //     image: 'https://via.placeholder.com/150x150',
    //     link: 'https://uk.westfield.com/london',
    //   },
  ];

  return (
    <div className="dark text-white min-h-screen flex flex-col items-center py-8 px-4 relative">
      <button onClick={() => window.history.back()} className="absolute top-4 left-4 flex items-center space-x-2 text-white text-lg font-semibold">
        <FaArrowLeft />
      </button>
      {/* Header */}
      <h1 className="text-4xl font-extrabold mb-8">Afternoon in Kensington</h1>

      {/* Experience Section */}
      <div className="mb-16 max-w-4xl">
        <h2 className="text-2xl font-semibold mb-4">Experience This</h2>
        <p className="leading-7 text-base mb-4">
          Yesterday was such a great day! My two friends and I decided to meet up in Kensington for
          a fun afternoon. We started at the Prada Café for lunch. The place was stunning—covered in
          flowers and the scent of roses. We sat down and just got lost in the moment while enjoying
          the most amazing truffle pasta, rose wine, and tiramisu. The dessert was the top of the
          top—tiramisu and a perfect little espresso to wake us up for an afternoon long.
        </p>
        <p className="leading-7 text-base mb-4">
          After lunch, we wanted to enjoy the nice weather, so we headed to Hyde Park for some
          paddle boating. The park was bustling with activity, but we still found our moments of
          calm on the lake as we rowed along. I love that place—and just being around the greenery
          made me peaceful. We even saw a swan glide by—another bonus!
        </p>
        <p className="leading-7 text-base">
          By the time we left, the sun was starting to set, so we all had our shopping bags in hand,
          feeling pretty pleased with ourselves. It was honestly the perfect afternoon—great food,
          fun at the park, and a bit of luxury shopping to finish it off.
        </p>
      </div>

      {/* Itinerary Section */}
      <div className="mb-16 max-w-4xl">
  <h2 className="text-2xl font-semibold mb-6 uppercase">Itinerary</h2>

  <div className="grid grid-cols-3 gap-8 items-start">
    {/* Left Column: Time and Details */}
    <div className="col-span-2 space-y-10">
      {itineraryData.map((item, index) => (
        <div key={index} className="flex items-start space-x-6">
          {/* Time Selection */}
          <div className="flex flex-col items-start">
            <label className="uppercase text-sm font-semibold mb-2">
              Select Time
            </label>
            <select
              className="bg-gray-900 text-white font-semibold rounded px-4 py-2 focus:outline-none border border-orange-500"
              value={item.time}
            >
              <option value={item.time}>{item.time}</option>
              {/* Add more time options if needed */}
            </select>
          </div>

          {/* Dotted Line */}
          <div className="flex flex-col items-center">
            {index < itineraryData.length - 1 && (
              <div className="h-16 border-l-2 border-dashed border-gray-500"></div>
            )}
          </div>

          {/* Details and Quantity */}
          <div className="flex-1">
            <p className="text-lg font-semibold">{item.name}</p>

            {/* Quantity Selector */}
            <div className="flex items-center space-x-4 mt-2">
              <span className="text-sm font-semibold uppercase">
                {item.name === 'Prada Café' ? 'Table for:' : 'Boats:'}
              </span>
              <select
                className="bg-gray-900 text-white font-semibold rounded px-2 py-1 border border-orange-500"
                defaultValue="1"
              >
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
              </select>
            </div>

            {/* Additional Note */}
            <p className="text-sm text-gray-300 mt-1">
              {item.description.includes('Note') ? item.description : `Note: ${item.description}`}
            </p>
          </div>
        </div>
      ))}
    </div>
    {/* Right Column: Clickable Images */}
    <div className="space-y-12"> {/* Increased y-axis gap */}
      {itineraryData.map((item, index) => (
        <div key={index} className="relative group cursor-pointer">
          <img
            src={item.image}
            alt={item.name}
            className="rounded-lg shadow-lg w-24 h-24 object-cover"
          />
          <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
            <span className="text-white font-semibold">CLICK TO VIEW</span>
          </div>
        </div>
      ))}
    </div>
  </div>

  {/* Book This Button */}
  <div className="text-center mt-8">
    <button className="bg-orange-500 text-black font-bold py-3 px-8 rounded-lg hover:bg-orange-600 transition-all">
      BOOK THIS
    </button>
  </div>
</div>


      {/* Recommendations Section */}
      <div className="mb-16 max-w-4xl">
        <h2 className="text-2xl font-semibold mb-6">Recommendations</h2>
        <div className="grid grid-cols-4 gap-8">
          {recommendations.map((rec, index) => (
            <div key={index} className="relative group cursor-pointer">
              <a href={rec.link} target="_blank" rel="noopener noreferrer">
                <img
                  src={rec.image}
                  alt={rec.name}
                  className="rounded-lg shadow-lg w-48 h-48 object-cover" // Changed width and height to make the boxes bigger and wider
                />
                <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                  <span className="text-white font-semibold">{rec.name}</span>
                </div>
              </a>
            </div>
          ))}
        </div>
      </div>

      {/* Relive This Button */}
      {/* <div className="text-center">
        <button className="bg-orange-500 text-black font-bold py-3 px-8 rounded-lg">
          RELIVE THIS
        </button>
      </div> */}
    </div>
  );
};

export default RelivAndBooking;
