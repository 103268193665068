import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FaBars, FaTimes, FaChevronDown, FaMapMarkerAlt } from 'react-icons/fa';
import finalLogo from '../../../assets/img/log.png';
import { useAuth } from '../../../auth/userProvider/AuthProvider';
import { useLoadScript } from '@react-google-maps/api';

const DashboardNav = ({ userLocation }) => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [activeTab, setActiveTab] = useState('/');
    const [locationName, setLocationName] = useState('');
    const navigate = useNavigate();
    const { userData, logout } = useAuth();
    const location = useLocation();
    const googleApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

    // Load Google Maps API only once
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: googleApiKey,
    });

    useEffect(() => {
        setActiveTab(location.pathname);
    }, [location.pathname]);

    // Fetch the location name from Google Maps API
    useEffect(() => {
        if (isLoaded && userLocation) {
            const fetchLocationName = async () => {
                try {
                    const response = await fetch(
                        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${userLocation.latitude},${userLocation.longitude}&key=${googleApiKey}`
                    );
                    const data = await response.json();
                    if (data.results && data.results.length > 0) {
                        // Extract the formatted address or a relevant part of the location
                        setLocationName(data.results[0].formatted_address);
                    }
                } catch (error) {
                    console.error('Error fetching location name:', error);
                }
            };

            fetchLocationName();
        }
    }, [userLocation, googleApiKey, isLoaded]);

    const handleTabClick = (path) => {
        navigate(path);
        setMobileMenuOpen(false);
    };

    const handleLogout = async () => {
        try {
            await logout(); // Call the logout function from your AuthContext
            navigate('/');
            console.log("User logged out successfully");
        } catch (error) {
            console.error("Logout error:", error.message);
        }
    };

    const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

    return (
        <nav className="bg-gray-950 text-white shadow-md">
            <div className="container mx-auto ">
                <div className="flex justify-between items-center h-16">
                    <div className="flex items-center">
                        <img src={finalLogo} alt="Logo" className="h-8 w-auto" />
                    </div>

                    <div className="flex items-center space-x-4 ml-auto">
                        {/* User Location */}
                        <div className="hidden md:flex">
                            {locationName ? (
                                <div className="flex items-center text-sm text-gray-400 border border-gray-700 px-3 py-1 rounded-lg hover:bg-gray-800 cursor-pointer" title={locationName}>
                                    <FaMapMarkerAlt className="mr-1" />
                                    {locationName.length > 20 ? `${locationName.slice(0, 20)}...` : locationName}
                                </div>
                            ) : (
                                <div className="flex items-center text-sm text-gray-400 border border-gray-700 px-3 py-1 rounded-lg bg-gray-800 hover:bg-gray-700">
                                    <FaMapMarkerAlt className="mr-1" />
                                    Fetching location...
                                </div>
                            )}
                        </div>
                        {/* User Dropdown */}
                        <div className="hidden md:flex">
                            <div
                                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                                className="text-gray-300 cursor-pointer flex items-center border border-gray-700 px-3 py-1 rounded-lg bg-gray-800 hover:bg-gray-700"
                            >
                                {userData?.firstName || userData?.name || 'User'} <FaChevronDown className="ml-1" />
                            </div>
                            {isDropdownOpen && (
                                <div className="absolute right-0 mt-2 w-48 bg-gray-800 text-gray-300 border border-gray-700 rounded-lg shadow-lg z-10">
                                    <div className="py-2 px-4 hover:bg-gray-700 cursor-pointer" >
                                        Profile
                                    </div>
                                    <div className="py-2 px-4 hover:bg-gray-700 cursor-pointer" onClick={handleLogout}>
                                        Sign Out
                                    </div>
                                </div>
                            )}
                        </div>

                        {/* Mobile menu button */}
                        <div className="md:hidden">
                            <button onClick={() => setMobileMenuOpen(!mobileMenuOpen)} className="text-white hover:text-gray-300 focus:outline-none">
                                {mobileMenuOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Mobile Menu */}
            {mobileMenuOpen && (
         <div className="md:hidden">
         <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3 text-center font-semibold tracking-wide">
         <MobileNavItem
             path="/user-dashboard/university"
             label="Universities"
             active={activeTab === '/user-dashboard/university'}
             onClick={handleTabClick}
           />
                  <MobileNavItem
             path="/user-dashboard/stay"
             label="Accomodation"
             active={activeTab === '/user-dashboard/stay'}
             onClick={handleTabClick}
           />
           <MobileNavItem
             path="/user-dashboard/events"
             label="Events"
             active={activeTab === '/user-dashboard/events'}
             onClick={handleTabClick}
           />
    
          
           <MobileNavItem
             path="/user-dashboard/jobs"
             label="Jobs"
             active={activeTab === '/user-dashboard/jobs'}
             onClick={handleTabClick}
           />
           <MobileNavItem
             path="/user-dashboard/explore"
             label="Explore"
             active={activeTab === '/user-dashboard/explore'}
             onClick={handleTabClick}
           />
           <MobileNavItem
             path="/user-dashboard/reliv"
             label="Reliv"
             active={activeTab === '/user-dashboard/reliv'}
             onClick={handleTabClick}
           />
           <MobileNavItem
             path="/user-dashboard/bookings"
             label="Bookings"
             active={activeTab === '/user-dashboard/bookings'}
             onClick={handleTabClick}
           />
      
           <MobileNavItem
             path="/user-dashboard/profile"
             label="Profile"
             active={activeTab === '/user-dashboard/profile'}
           />
                <MobileNavItem
             path="/logout"
             label="Logout"
             active={false}
             onClick={handleLogout}
           />
         </div>
       </div>
       
            )}
        </nav>
    );
};

const MobileNavItem = ({ path, label, active, onClick }) => (
    <button
    onClick={() => onClick(path)}
    className={`block w-full px-3 py-2 rounded-md text-base font-medium text-center ${
        active ? 'bg-gray-900 text-white underline decoration-blue-500 decoration-4' : 'text-gray-300 hover:bg-gray-700 hover:text-white'
    }`}
>
    {label}
</button>

);

export default DashboardNav;
